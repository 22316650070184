/** @jsxImportSource @emotion/react */
import { css} from '@emotion/react';
import { Box, CircularProgress } from '@mui/material'
import { Spin } from 'antd';

export default function Loader({btn,bet}) {
  return (
    <Box sx={{ display: 'flex',justifyContent: "center",marginTop: `${btn ? "0rem": '10rem'}`}}><Spin style={{fontSize: btn? '0.2rem': ''}}/></Box>
  )
}
