import React,{useState} from 'react'
 /** @jsxImportSource @emotion/react */
 import { css} from '@emotion/react';
import { useLocation } from 'react-router-dom';
import { useContext } from 'react';
import moment from 'moment';

import GoBackHeader from '../goBackHeader'
import { getLangJson, languageContext } from '../../context';

export default function NewsDetails() {
  const {lang} = useContext(languageContext)
  const text = getLangJson(lang);
  const location = useLocation();
  const [height,setHeight] = useState(window.innerHeight);

  const {newsDetails: news} = location.state;
  window.addEventListener('resize', () => {
    setHeight(window.innerHeight)
  });
  return (
    <div >
        <GoBackHeader name={text.News}/>
        <div  css={css`
            overflow-y: scroll;
            height: calc(${height}px - 82px);
            -ms-overflow-style: none;  /* Internet Explorer 10+ */
            scrollbar-width: none;  /* Firefox */
            ::-webkit-scrollbar { 
            display: none !important;  /* Safari and Chrome */
           }
          `}>
          <img src={news.feature_img} style={{width: "100%",height: "23.6rem",marginBottom: "1.9rem",objectFit: "contain"}}/>
           <h1 style={{margin: 0,fontSize: "1.8rem",color: "#D8BFBF",padding: "0 1.5rem 1.2rem 1.5rem"}}>{news.title}</h1>
           <h1 style={{margin: 0,fontSize: "1.6rem",fontWeight: "normal",color: "grey",padding: "0 1.5rem 1.2rem 1.5rem"}}> {moment(news.createdAt).format("MMM DD,YYYY h:mm A")}</h1>
           <h1 style={{margin: 0,fontSize: "1.6rem",fontWeight: "normal",padding: "0 1.5rem 1.2rem 1.5rem"}} className="white">
            {news.description}
           </h1>
           <h1 style={{margin: 0,fontSize: "1.2rem",fontWeight: "normal",padding: "0 1.5rem 1.2rem 1.5rem"}} className="white">Ref:{news.source_url}</h1>
        </div>
    </div>
  )
}
