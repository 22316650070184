 /** @jsxImportSource @emotion/react */
 import { css} from '@emotion/react';
import React, { useContext,useState,useEffect,useRef  } from 'react'
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Footer from '../footer'
import { ButtonBase } from '@mui/material';
import {Tab, Tabs} from '@mui/material'
import { Avatar } from '@mui/material'
import moment from 'moment';
import {Img} from 'react-image'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import GoBackHeader from '../goBackHeader';
import { getLangJson, languageContext } from '../../context';
import axios from '../apiClient';
import Loader from '../loader';
import Alert from '../alert'
import NoData from '../noData';
import config from '../../config';

export default function GoalResult() {
  const {lang} = useContext(languageContext)
  const text = getLangJson(lang);
  const [filterOpen, setFilterOpen] = useState(false)
  const [loading,setLoading] = useState(true)
  const [errorMsg, setErrorMsg] = useState({});
  const [openAlert, setOpenAlert] = useState(false);
  const [days,setDays] = useState([])
  const [selectedDay,setSelectedDay] = useState({value: moment().format('YYYY-MM-DD'),label: "Today"})
  const [height,setHeight] = useState(window.innerHeight);
  const [goalResult,setGoalResult] = useState([]) 
  const didMount = useRef(false);
  const [favLeagues, setFavLeagues] = useState([])
  const [filteredLeagues, setFilteredLeagues] = useState([])

  async function fetchData() {
    setLoading(true)

    const data = await axios.get(`/report/matchResult?matchDate=${selectedDay.value}&leagues=${getFilteredLeaguesString()}`)
    if (data.code != 200) {
        if(data.code == 404){
            setLoading(false)
            setGoalResult([])
            return
        }
        setErrorMsg({ code: data.code, msg: data.message })
        setOpenAlert(true)
        setLoading(false)
        return
    }
    setGoalResult(data.data)
    setLoading(false)
  }

  function getFilteredLeaguesString(){
    let str = "" ;
    for (let i = 0; i < filteredLeagues.length; i++) {
        const v = filteredLeagues[i];
        if(i +1 == filteredLeagues.length){
            str += v 
        }else{
            str += v + ","
        }
    }
    return str;
  }

  window.addEventListener('resize', () => {
    setHeight(window.innerHeight)
  });

  useEffect(()=>{
    fetchData()
    if( !didMount.current){ // only call once
      fetchFavLeagues()
      getLast7DaysAndComing7days();
      didMount.current = true;
    }
  },[selectedDay,filteredLeagues])

  const fetchFavLeagues = async() =>{
    const data = await axios.get(`/info/getLeague?s=0&c=1`)
    if(data.code != 200) return setLoading(false)

    setFavLeagues(data.data.favLeauges)
  }

  const handleFavLeaguesClick = (leagueId) =>{
    let copyArr = [...filteredLeagues];
    (filteredLeagues.includes(leagueId))? copyArr = filteredLeagues.filter(v=>v != leagueId) : copyArr.push(leagueId)
    setFilteredLeagues(copyArr)
  }


  const handleFilterClick = () => (filterOpen) ? setFilterOpen(false) : setFilterOpen(true)

  const getLast7DaysAndComing7days = () =>{
    const dates = [];
    const currentDate = moment();

    // Add the last 7 days
    for (let i = 6; i >= 0; i--) {
      const obj = {};
      obj.value = currentDate.clone().subtract(i, 'days').format('YYYY-MM-DD');
      if (currentDate.isSame(obj.value, 'day')){
        obj.label = "Today"
      }else{
        obj.label = capitalizeFirstLetter(currentDate.clone().subtract(i, 'days').format('MMM D'));
      }
      dates.push(obj)
    }

    // Add the coming 7 days
    for (let i = 1; i <= 7; i++) {
      const obj = {};
      obj.value = currentDate.clone().add(i, 'days').format('YYYY-MM-DD');
      obj.label = capitalizeFirstLetter(currentDate.clone().add(i, 'days').format('MMM D'));
      dates.push(obj);
    }
    setDays(dates);
  }

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const handleDayChange = (day) =>{
    setSelectedDay(day);
    setFilterOpen(false)
  }

  const handleCloseAlert =()=>{
    setOpenAlert(false)
  }
 
  return (
    <div >
      <GoBackHeader hideArrow name={text['Goal Result']}/>
      <div style={{padding: "2rem 2rem 2rem 2rem"}}  >
        <Alert openAlert={openAlert} errorMsg={errorMsg} handleCloseAlert={handleCloseAlert}/>

          <div style={{display: "flex",flexDirection: "column",marginBottom: "1rem"}}>
              <div style={{display: 'flex',justifyContent: "space-between"}}>
               <div style={{display: "flex",flexDirection: "row",justifyContent: "space-evenly",flexBasis: "68%"}}>
                {favLeagues?.map((v) => {
                  return (
                  <ButtonBase style={{borderRadius: "50px"}} key={v.id}>
                      <Avatar  sx={{borderColor: "red",borderWidth: `${filteredLeagues.includes(v.id)? '2px': "0px"}`,borderStyle: 'solid',cursor: "pointer"}} onClick={()=>handleFavLeaguesClick(v.id)}>
                         <Img  src={[v.logo,config.defaultLogo]} alt="Logo" style={{objectFit: "cover",borderRadius: "50px"}} width='100%' height='100%'  loader={<Skeleton width={40} height={40} circle/>}/>  
                      </Avatar>
                  </ButtonBase>
                )
               })}
              </div>
                <div style={{}}>
                  <ButtonBase style={{alignItems: "center",padding: "1rem 0 1rem 1rem",backgroundColor: "#0A324D",borderRadius: `${filterOpen? "0.9rem 0.9rem 0 0" : "0.9rem"}`,marginLeft: "2rem",fontFamily: "inherit"}} onClick={handleFilterClick}>
                    <h4 style={{margin: 0,flexWrap: "nowrap"}} className="white">{selectedDay.label}</h4>
                    {filterOpen ? <ExpandLess style={{width: "3.6rem",height: "2rem",borderRadius: "3rem",marginTop: 0,color: "white"}}/> : <ExpandMore style={{width: "3.6rem",height: "2rem",borderRadius: "3rem",marginTop: 0,color: 'white'}}/>}
                  </ButtonBase>
                </div>
              </div>
              {filterOpen &&
                   <Tabs
                    value={selectedDay.value}
                    indicatorColor="primary"
                    variant="scrollable"
                    scrollButtons
                    allowScrollButtonsMobile
                    aria-label="scrollable force tabs example"
                    sx={{fontFamily: "inherit",borderRadius: "0.9rem 0 0.9rem 0.9rem",backgroundColor: "rgb(10, 50, 77)",color: "white",".css-1aquho2-MuiTabs-indicator": {backgroundColor: "#87CEEB"},".css-85ccme-MuiButtonBase-root-MuiTab-root.Mui-selected": {color: "#87CEEB"},".css-85ccme-MuiButtonBase-root-MuiTab-root": {textTransform: "none"}}}
          
                  >
                      {days.map((day, index) =>
                      {
                      return (
                        <Tab key={index} value={day.value}  label={day.label} sx={{fontFamily: 'inherit',color: 'white',fontSize: "1.2rem"}} onClick={()=>handleDayChange(day)}/>
                      )})}
                </Tabs> }
          </div>
        <div>
        {loading && <Loader bet/>}
        {!loading &&
        (goalResult.length == 0 ? <NoData msg={text['There is no bet match']} height="60vh"/> :
          <div
          css={css`
            overflow-y: auto;
            height: calc(${height}px - 210px);
            -ms-overflow-style: none;  /* Internet Explorer 10+ */
            scrollbar-width: none;  /* Firefox */
            ::-webkit-scrollbar { 
              display: none;  /* Safari and Chrome */
          }
          `}
          >
           { goalResult.map(({league,match})=>{
              return(
                  <div key={league.id} style={{marginTop: "4rem"}}>
                      <div style={{display: "flex",alignItems: 'center'}}>
                        <Avatar style={{width: "3rem",height: "3rem",borderRadius: "3rem"}}>
                        <Img style={{width: "3rem",height: "3rem",borderRadius: "3rem",objectFit: 'contain'}} src={[league.logo,config.defaultLogo]} loader={<Skeleton style={{width: "3rem",height: "3rem"}} circle/>}/>
                        </Avatar>
                        <h3 className='white' style={{margin: 0,marginLeft: "0.8rem"}}>{(lang == "unicode" || lang == "zawgyi") ? league.name_mm : league.name_en }</h3>
                      </div>
                      {match.map((v)=>{
                        return (
                          <Card key={v.matchId} matchData={v}/>
                        )
                      })}
                  </div>
              )
            })}
          </div>
        )
        }
        </div>
      </div>
      <Footer/>
    </div>
  )
}

function Card({matchData}) {

  const {homeTeam,awayTeam,goals,status,matchDate,scores} = matchData;
  const {halftime} = scores;
  const {lang} = useContext(languageContext)

  return (
    <div style={{margin: "2rem 0"}}>
      <div style={{display: 'flex',marginBottom: "0.3rem"}}>
        <h5 style={{margin: 0,color: "gray"}} className="white">{moment(matchDate).format('DD-MM-YYYY h:mmA')}</h5>
        <h5 style={{margin: 0,marginLeft: "auto",color: 'gray'}} className="white">1H</h5>
        <h5 style={{margin: 0,marginLeft: "2rem",marginRight: "1rem",color: "gray"}} className="white">FT</h5>
      </div>
      <ButtonBase component='div' style={{display: 'block'}}>
        <div style={{display: "flex",backgroundColor: "#0A324D",padding: "1.2rem 1.7rem",borderRadius: "1.3rem",alignItems: "center"}}>
          {/* <div style={{paddingRight: '1rem'}}>
            <h4 style={{margin: 0}} className="white">{status}</h4>
          </div> */}
          <div style={{display: "flex",flexDirection: "column",width: "100%"}}>
            <div style={{display: "flex",alignItems: "center",marginBottom: "0.5rem"}}>
              <Img style={{width: "2.6rem",height: "2.6rem",borderRadius: "3rem",paddingRight: "1rem"}} src={[homeTeam.logo,config.defaultLogo]} loader={<Skeleton style={{width: "2.6rem",height: "2.6rem"}} circle/>}/>
              <h5 style={{margin: 0}} className="white"> {lang != "en" ? homeTeam.name_mm : homeTeam.name_en}</h5>
              <h4 style={{margin: 0,marginLeft: "auto"}} className="white"><span>{halftime.home != null ? halftime.home : "-"}</span></h4>
              <h4 style={{margin: 0,marginLeft: "2rem"}} className="white"><span>{goals.home != null ? goals.home : "-"}</span></h4>
            </div>
            <div style={{display: "flex",alignItems: "center"}}>
               <Img style={{width: "2.6rem",height: "2.6rem",borderRadius: "3rem",paddingRight: "1rem"}} src={[awayTeam.logo,config.defaultLogo]} loader={<Skeleton style={{width: "2.6rem",height: "2.6rem"}} circle/>}/>
               <h5 style={{margin: 0}} className="white">{lang != "en" ? awayTeam.name_mm : awayTeam.name_en}</h5>
               <h4 style={{margin: 0,marginLeft: "auto"}} className="white"><span>{halftime.away != null ? halftime.away : "-"}</span></h4>
               <h4 style={{margin: 0,marginLeft: "2rem"}} className="white"><span>{goals.away != null ? goals.away : "-"}</span></h4>
            </div>
          </div>
        </div>
      </ButtonBase>
    </div>
  )
}