 /** @jsxImportSource @emotion/react */
 import { css} from '@emotion/react';
import React, { useContext , useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component';
import moment from 'moment';

import GoBackHeader from '../goBackHeader'
import { getLangJson, languageContext } from '../../context'
import Loader from '../loader';
import { useEffect } from 'react';
import axios from '../apiClient';
import Alert from '../alert';
import NoData from '../noData';
import { Avatar, Badge } from 'antd';

export default function WithdrawalRequestList() {
  const {lang} = useContext(languageContext);
  const [requests,setRequests] = useState([]);
  const [hasMore,setHasMore] = useState(true);
  const [errorMsg, setErrorMsg] = useState({});
  const [openAlert, setOpenAlert] = useState(false);
  const [loading,setLoading] = useState(true);
  const [page,setPage] = useState(0);

   async function fetchData(fetchMore) {

    const current = page + 1;
    // const data = await axios.get(`/api/wallet/getTransaction?holderId=${holderId}&s=${from}&c=${to}`)
    const data = await axios.get(`/wallet/getWithdrawRequestByMemberId?current=${current}&pageSize=10`)

    if (data.code != 200) {
        if(data.code == 404){
          setLoading(false)
            if(fetchMore){
              setHasMore(false)
            }else{
              setRequests([])
            }
            return;
        }
        setErrorMsg({ code: data.code, msg: data.message })
        setOpenAlert(true)
        setLoading(false)
        return
      }
    setPage(current);
    const combineArr =[...requests,...data.data.withdraw_request]
    setLoading(false)
    setRequests(combineArr)
   }

  useEffect(()=>{
    fetchData()
  },[])

  const fetchMoreData = ()=>{
    fetchData(true)
  }
  const handleCloseAlert =()=> setOpenAlert(false)

  const text = getLangJson(lang);
  return (
    <div>
        <GoBackHeader name={text['Withdrawal Request Lists']}/>
        {loading && <Loader/>}
        {!loading &&
         <div>
           <Alert openAlert={openAlert} errorMsg={errorMsg} handleCloseAlert={handleCloseAlert}/>
           { 
            requests.length == 0 ? 
            <NoData msg={text['There is no withdrawal request lists']} height="60vh"/>
            :
            <InfiniteScroll
                css={css`
                -ms-overflow-style: none;  /* Internet Explorer 10+ */
                scrollbar-width: none;  /* Firefox */
                ::-webkit-scrollbar { 
                display: none !important;  /* Safari and Chrome */
                }
              `}
                dataLength={requests.length} //This is important field to render the next data
                next={fetchMoreData}
                hasMore={hasMore}
                loader={ <Loader btn/>}
                // scrollableTarget="betHistory"
                endMessage={
                  <p style={{ textAlign: 'center',color: 'white' }}>
                    <b style={{color: 'white' }}>End</b>
                  </p>
                }
                >
                { requests.map((r,index)=> {
                    return (
                      <Card key={index} request={r} text={text}/>
                    )
                })}   
            </InfiniteScroll>
          }
         </div>
        
        }
      
    </div>
  )
}


function Card({request,text}) {
  const {amount,otp,payment_method,requested_at,status} = request;
  
  const successColor = "#005853";
  const pendingColor = "rgb(21 106 141)";
  const color = status == 'pending' ? pendingColor: successColor;
  const badgeText = status == "pending"? text["Pending"] : text["Success"];
  return (
    <div style={{margin: "1.5rem 1.5rem"}}>
      <Badge.Ribbon text={badgeText} style={{color: color,backgroundColor: color}} >
      <div style={{display: 'flex',justifyContent: "space-between",padding: "2rem 1.5rem 2rem 1.5rem",backgroundColor: "#0A324D",borderRadius: "1rem"}}>
        <div style={{display: 'flex',flexDirection: "column"}}>
          <div style={{display: "flex",marginBottom: '1.2rem'}}>
            {otp.toString().split('').map((o,index) => {
                return <Avatar key={index} shape="square" size="small" style={{marginRight: "0.8rem"}} >{o}</Avatar>
            })}

          </div>
          {/* <h4 style={{margin: "0 0 0.5rem 0",fontFamily: "monospace"}} className="white">{otp}</h4> */}
          <h1 style={{margin: 0,fontSize: "1.2rem",fontWeight: "normal"}} className="white">{moment(requested_at).format('MMM DD,YYYY h:mmA')}</h1>
        </div>
        <div style={{display: 'flex',justifyContent: "center",alignItems: "center"}}>
          <h3 className='white' style={{margin: 0,paddingTop: "1.2rem"}}>{Number(amount).toLocaleString()}</h3>
        </div>
         </div>
         </Badge.Ribbon>
    </div>
  )
}

