 /** @jsxImportSource @emotion/react */
 import { css} from '@emotion/react';
import React, { useEffect } from 'react'
import GoBackHeader from '../goBackHeader'
import { useContext } from 'react'
import { getLangJson, languageContext } from '../../context'
import { useState } from 'react'

import axios from '../apiClient';
import Alert from '../alert'
import Loader from '../loader'
import InfiniteScroll from 'react-infinite-scroll-component'
import moment from 'moment'
import NoData from '../noData'

export default function AccountStatement() {
    const [errorMsg, setErrorMsg] = useState({});
    const [openAlert, setOpenAlert] = useState(false);
    const [loading,setLoading] = useState(true)
    const [statements,setStatements] = useState([])
    const [hasMore,setHasMore ] =useState(true)

    const {lang} = useContext(languageContext)
    const text = getLangJson(lang);

    async function fetchData(fetchMore) {
        const from = statements.length;
        const to = statements.length + 10;
        const last30Days = moment().subtract(30,'days').format('YYYY-MM-DD');
        const today = moment().format('YYYY-MM-DD');
        const res = await axios.get(`/report/getStatementByMemberId?startDate=${last30Days}&endDate=${today}&s=${from}&c=${to}`)
    
        if (res.code != 200) {
            if(res.code == 404){
              setLoading(false)
              if(fetchMore){
                setHasMore(false)
              }else{
                setHasMore(false)
                setStatements([])
              }
              return;
            } 
            setErrorMsg({ code: res.code, msg: res.message })
            setOpenAlert(true)
            setLoading(false)
            return
        }
        setLoading(false)
        const combineArr = [...statements,...res.data]
        setStatements(combineArr)
    }
    const handleCloseAlert =()=> setOpenAlert(false)

    useEffect(()=>{
        fetchData()
    },[])
    const fetchMoreData = ()=>{
        fetchData(true)
    }

  return (
    <div>
        <GoBackHeader name={text['Account Statement']}/>
        <Alert openAlert={openAlert} errorMsg={errorMsg} handleCloseAlert={handleCloseAlert}/>
        {loading && <Loader/>}
        {!loading && statements.length > 0 &&

         <InfiniteScroll
            css={css`
            -ms-overflow-style: none;  /* Internet Explorer 10+ */
            scrollbar-width: none;  /* Firefox */
            ::-webkit-scrollbar { 
             display: none !important;  /* Safari and Chrome */
            }
           `}
            dataLength={statements.length} //This is important field to render the next data
            next={fetchMoreData}
            hasMore={hasMore}
            loader={ <Loader btn/>}
            // scrollableTarget="betHistory"
            endMessage={
              <p style={{ textAlign: 'center',color: 'white' }}>
                <b style={{color: 'white' }}>End</b>
              </p>
            }
            >
              {
                statements.map((statement,index)=> {
                return (
                    <Card key={index} statement={statement}/>
                )
              }) 
             }
         </InfiniteScroll>
        }
        {!loading && statements.length == 0 &&
          <NoData msg={text['There is no statements for this month']} height="60vh"/> 
          // <h1 style={{color: 'white'}}>Hello</h1>
        }
    </div>
  )
}


function Card({statement}) {
    const {lang} = useContext(languageContext)
    const text = getLangJson(lang);
    const {afterAmount,beforeAmount,bettingAmount,depositAmount,period_end,period_start,winAmount,withdrawAmount,refundAmount} = statement;
  return (
    <div style={{padding: "1.9rem 1.7rem"}}>
        <div style={{padding: "2rem 2.5rem",backgroundColor: "#0A324D",borderRadius: "2.2rem"}}>
            <div style={{display: "flex",flexDirection: "column",borderWidth: "0 0 1px 0",borderStyle: "dashed",borderColor: "grey"}}>
                <div style={{display: "flex",justifyContent:"space-between",margin: "0 0 0.8rem 0"}}>
                    <h5 style={{margin: 0,fontWeight: "normal"}} className="white">{text['Yesterday balance']}</h5>
                    <h4 style={{margin: 0,fontWeight: "bold"}} className="white">{Number(beforeAmount).toLocaleString()}</h4>
                </div>
                <div style={{display: "flex",justifyContent:"space-between",margin: "0 0 0.8rem 0"}}>
                    <h5 style={{margin: 0,fontWeight: "normal"}} className="white">{text.Deposit}</h5>
                    <h4 style={{margin: 0,fontWeight: "bold"}} className="white">{Number(depositAmount).toLocaleString()}</h4>
                </div>
                <div style={{display: "flex",justifyContent:"space-between",margin: "0 0 0.8rem 0"}}>
                    <h5 style={{margin: 0,fontWeight: "normal"}} className="white">{text.Withdrawl}</h5>
                    <h4 style={{margin: 0,fontWeight: "bold"}} className="white">{Number(withdrawAmount).toLocaleString()}</h4>
                </div>
            </div>
            <div style={{display: "flex",flexDirection: "column",borderWidth: "0 0 1px 0",borderStyle: "dashed",borderColor: "grey",marginTop: "0.8rem"}}>
                <div style={{display: "flex",justifyContent:"space-between",margin: "0 0 0.8rem 0"}}>
                    <h5 style={{margin: 0,fontWeight: "normal"}} className="white">{text['Betted money']}</h5>
                    <h4 style={{margin: 0,fontWeight: "bold"}} className="white">{Number(bettingAmount).toLocaleString()}</h4>
                </div>
                {/* <div style={{display: "flex",justifyContent:"space-between",margin: "0 0 0.8rem 0"}}>
                    <h5 style={{margin: 0,fontWeight: "normal"}} className="white">{text.Commision}</h5>
                    <h4 style={{margin: 0,fontWeight: "bold"}} className="white">0.00</h4>
                </div> */}
                <div style={{display: "flex",justifyContent:"space-between",margin: "0 0 0.8rem 0"}}>
                    <h5 style={{margin: 0,fontWeight: "normal"}} className="white">{text['Refund Amount']}</h5>
                    <h4 style={{margin: 0,fontWeight: "bold"}} className="white">{Number(refundAmount).toLocaleString()}</h4>
                </div>
                <div style={{display: "flex",justifyContent:"space-between",margin: "0 0 0.8rem 0"}}>
                    <h5 style={{margin: 0,fontWeight: "normal"}} className="white">{text['Win/lose money']}</h5>
                    <h4 style={{margin: 0,fontWeight: "bold"}} className="white">{Number(winAmount).toLocaleString()}</h4>
                </div>
            </div>
            <div style={{display: "flex",flexDirection: "column",marginTop: "0.8rem"}}>
                <div style={{display: "flex",justifyContent:"space-between",margin: "0 0 0.8rem 0"}}>
                    <h5 style={{margin: 0,fontWeight: "normal"}} className="white">{text.Balance}</h5>
                    <h4 style={{margin: 0,fontWeight: "bold"}} className="white">{Number(afterAmount).toLocaleString()}</h4>
                </div>
            </div>
            <div style={{display: "flex",flexDirection: "column"}}>
                <h1 style={{margin: 0,fontSize: "1.1rem",color: "#7BF2B2",fontWeight: "normal"}} className="white">{lang != "en" ? `${period_start.split(/[ ,]+/)[0]} ${text['From Evening 12 o\'clock']}`: `${text['From Evening 12 o\'clock']},${period_start.split(/[ ,]+/)[0]}`}</h1>
                <h1 style={{margin: "0.8rem 0 0 0",fontSize: "1.1rem",color: "#7BF2B2",fontWeight: "normal"}} className="white">{(lang != "en") ? `${period_end.split(/[ ,]+/)[0]} ${text['To Evening 12 o\'clock']}` : `${text['To Evening 12 o\'clock']},${period_end}`}</h1>
            </div>
        </div>
    </div>
  )
}

