import React from 'react'

export default function NoData({msg,height}) {
 
  return (
    <div style={{color: "white",display: "flex",justifyContent: "center",alignItems: "center",height: `${height}`}}>
        <h3 style={{fontSize: "1.4rem",color: 'grey'}}>{msg}</h3>
    </div>
  )
}
