import React from 'react'
import GoBackHeader from '../goBackHeader'

export default function Noti() {
  return (
    <div>
        <GoBackHeader name="Notification"/>
        <Card/>
        <Card/>
    </div>
  )
}
function Card() {
    return (
      <div style={{display: 'flex',justifyContent: "space-between",padding: "2rem 1.5rem",margin: "1.5rem 1.5rem",backgroundColor: "#0A324D",borderRadius: "1rem"}}>
        <div style={{display: 'flex',flexDirection: "column"}}>
          <h4 style={{margin: "0 0 0.5rem 0",fontFamily: "'Inter'"}} className="white">Promotion</h4>
          <h1 style={{margin: "1rem 0", fontSize: "1.4rem",fontFamily: "'Inter'"}} className="white">You have a new facebook suggestion</h1>
          <h1 style={{margin: 0,fontSize: "1.2rem",fontWeight: "normal"}} className="white">Jan 07,2023 6:57PM</h1>
        </div>
     </div>
    )
  }