import React,{useState} from 'react'
 /** @jsxImportSource @emotion/react */
 import { css} from '@emotion/react';
 
import GoBackHeader from '../../goBackHeader'
import BodyUNBody from './bodyUNBody'
import { getLangJson, languageContext } from '../../../context'

export default function BodyUN() {
  const {lang} = React.useContext(languageContext); 
  const [height,setHeight] = useState(window.innerHeight);

  // window.addEventListener('resize', () => {
  //   setHeight(window.innerHeight)
  // });
  let text = getLangJson(lang);
  // css={css`
  //   overflow-y: auto;
  //   height: calc(${height}px - 184px);
  //   -ms-overflow-style: none;  /* Internet Explorer 10+ */
  //   scrollbar-width: none;  /* Firefox */
  //   ::-webkit-scrollbar { 
  //      display: none;  /* Safari and Chrome */
  //  }
  // `}
  return (
    <div className='white ' >
        <GoBackHeader name={text['Body/UN']}/>
        <BodyUNBody/>
    </div>
  )
}
