import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { IconButton } from '@mui/material';

export default function GoBackHeader({name,hideArrow}) {
    const navigate = useNavigate();
    const location = useLocation();
    const handleGoBack=()=>{
      //  if(location.pathname == "/newsDetails"){
      //    navigate('/news',{state: location.state})
      //  }else if((location.pathname == "/bettedDetail")){
      //    navigate('/bettedHistory',{state: location.state})
      //  }else if((location.pathname == "/bettedHistory")){
      //   navigate('/')
      //  }else{
        //  }
        navigate(-1)
    }
  return (
    <div style={{position: "sticky",top: 0,right: 0,left: 0,zIndex: 1000}}>
        <div style={{position: "relative"}}>
          {
          !hideArrow  && 
          <IconButton color='white' onClick={handleGoBack} style={{position: "absolute",top: "10px",left: '10px',width: "50px",height: "50px"}} sx={{'&:hover': {opacity: "0.8"}}}>
            <ArrowBackIcon style={{width: "2.2rem",height: "2.2rem",color: "white"}}/>
          </IconButton>
          }
          <div style={{display: 'flex',justifyContent: "center",backgroundColor: "#021928",padding: "2.4rem 0"}}>
              <h3 style={{margin: 0}} className="white">{name}</h3>
          </div>
        </div>
    </div>
  )
}
