import React,{useState, useEffect} from 'react'
import parse from 'html-react-parser';
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css';

import Alert from '../alert';
import axios from '../apiClient';
import GoBackHeader from '../goBackHeader'
import { getLangJson, languageContext } from '../../context';

export default function Rules() {
  const {lang} = React.useContext(languageContext); 
  let text = getLangJson(lang);

  const [openAlert, setOpenAlert] = useState(false);
  const [errorMsg, setErrorMsg] = useState({});
  const [rules, setRules] = useState();

  const getRules = async () => {
    const res = await axios.get(`/info/getRules`)
  
    if (res.code != 200) {
      return
    }
    const desc = JSON.parse(res.data.description);
    setRules(desc);
  }

  useEffect(()=>{
    getRules();
  },[])

  const handleCloseAlert =()=>{
    setOpenAlert(false)
  }

  return (
    <div>
        <GoBackHeader name={text["Rules"]}/>
        <div  className="ql-editor" style={{padding: "2rem 1rem"}}>
          {rules && parse(rules)}
        </div>
    </div>
  )
}
