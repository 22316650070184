 /** @jsxImportSource @emotion/react */
 import { css} from '@emotion/react';
import React, { useState } from 'react'

import GoBackHeader from '../../goBackHeader'
import ParlayBody from './parlayBody'
import { getLangJson, languageContext } from '../../../context'


export default function Parlay() {

  const [height,setHeight] = useState(window.innerHeight);
  const {lang} = React.useContext(languageContext); 
  let text = getLangJson(lang);

  // window.addEventListener('resize', () => {
  //   setHeight(window.innerHeight)
  // });
//   css={css`
//   overflow-y: auto;
//   height: calc(${height}px - 158px});
//   -ms-overflow-style: none;  /* Internet Explorer 10+ */
//   scrollbar-width: none;  /* Firefox */
//   ::-webkit-scrollbar { 
//      display: none;  /* Safari and Chrome */
//  }
// `}
  return (
    <div className='white '  
    >
        <GoBackHeader name={text.Parlay}/>
        <ParlayBody/>
    </div>
  )
}
