import React,{useContext, useState} from 'react'
import {ButtonBase } from '@mui/material';
import moment from 'moment/moment';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import FiberManualRecordRoundedIcon from '@mui/icons-material/FiberManualRecordRounded';

import { getLangJson, languageContext } from '../../context';
import { useEffect } from 'react';
import { Rabbit, uni2zg } from '../../rabbit';

export default function Card({ matchData, handleBetMatchClick, selectedBetCard, summary, betTime,goals,handleOpenComfirmBox,parlay,inBet }) {
    const oddNormalColor = '#87CEEB';
    // const oddChangedColor = 'rgb(127 200 146)';
    const oddChangedColor = 'rgb(194 127 200)';

    const {lang} = useContext(languageContext); 
    const text = getLangJson(lang);

     // sorting odd period order [full-time,first-time,second-time]
    //  let arr = [];
    //  matchData?.odd?.forEach(odd => {
    //      if(odd?.periods == 'full_time') arr[0] = odd;
    //      if(odd?.periods == 'first_time') arr[1] = odd;
    //      if(odd?.periods == 'second_time') arr[2] = odd;
    //  })
    //  matchData.odd = arr;
    const firstArrPeriod = !matchData?.odd[0] ? "" : matchData?.odd[0]?.periods;

    const big = matchData?.big == '1' ? true : false;

    const [matchTimeRange, setMatchTimeRange] = React.useState(`${summary ? betTime : firstArrPeriod}`);

    const originalselectedBetCard = [...selectedBetCard];

    const dotAppear = (periods) => {
        return originalselectedBetCard.length > 0  && originalselectedBetCard.some(card => card.periods == periods && matchData.matchId == card.matchId);
    }

    selectedBetCard = [...selectedBetCard].filter(v=>v.periods == matchTimeRange )[0]; // if user selected more than one periods in one match

    if(summary){
        // if match card is in summay page, only show user's chose period
        matchData.odd = matchData?.odd?.filter(odd => odd?.periods == selectedBetCard.periods);
    }
    const [hdpOddColor,setHdpOddColor] = useState(oddNormalColor);
    const [ouOddColor,setOuOddColor] = useState(oddNormalColor);

    const handleBetTimeRangeOptionClick = (matchId, period) => {
        setMatchTimeRange(period)
        // handleBetMatchClick({ matchId }) // to remove selection in that match
    }
    
    useEffect(()=>{
        if(matchData?.odd?.length == 1){
           let periods = matchData.odd[0].periods;
            setMatchTimeRange(periods)
        }
       
    })

   
    const bettingSelectedColor = "#0e524d"

    function getSlipStatusBg(){
        let backgroundColor;
        if(status == 'pending') backgroundColor= "#A47E3B"
        if(status == 'win') backgroundColor= "#408E91"
        if(status == 'loss') backgroundColor= "#9C254D"
        if(status == 'refund') backgroundColor= "rgb(100 39 118)"
        if(status == 'draw') backgroundColor= "#9C254D"
        if(status == "partially win") backgroundColor = '#408E91' ;
        if(status == "partially lose") backgroundColor = '#9C254D' ;
        return backgroundColor;
    }
    
    function getSlipStatusText(){
       let word;
       if(status == "pending") word = text.Playing ;
       if(status == "win") word = text.Win ;
       if(status == "loss") word = text.Lose ;
       if(status == "refund") word = text.Refund ;
       if(status == "draw") word = text.Lose ;
       if(status == "partially win") word = text['Partially Win'] ;
       if(status == "partially lose") word = text['Partially Lose'] ;
       return word;
    }

    const hdpConverted = matchData?.odd?.filter(o => o?.periods == matchTimeRange)[0]?.hdp?.converted;

    const ouConverted = matchData?.odd?.filter(o => o?.periods == matchTimeRange)[0]?.ou?.converted;

    setTimeout(()=>{  
        let hdpChanged = matchData?.odd?.filter(o => o?.periods == matchTimeRange)[0]?.hdp?.change;
        let ouChanged = matchData?.odd?.filter(o => o?.periods == matchTimeRange)[0]?.ou?.change;

        if(hdpChanged){
            setHdpOddColor(oddChangedColor)
        }else{
            setHdpOddColor(oddNormalColor)
        }
        if(ouChanged){
            setOuOddColor(oddChangedColor)
        }else{
            setOuOddColor(oddNormalColor)
        }
    },500)

    let status = matchData?.status;

    const home_team_has_hadicap = matchData?.odd?.filter(o => o?.periods == matchTimeRange)[0]?.hdp?.home_team_has_hadicap;

    if(selectedBetCard){
        const converted = selectedBetCard.converted;
        const middleSign = converted.charAt(converted.split(/[+-]/)[0].length);
        // if(status == "draw"){
        //     if(middleSign == "+") {
        //         if(selectedBetCard.oddType == "ou"){
        //             if(selectedBetCard.selectedTeam == "over") {
        //                 status = "partially win"
        //             }else{
        //                 status = "partially lose"
        //             }
        //         }else{
        //             if(home_team_has_hadicap) {
        //                 if(selectedBetCard.selectedTeam == "home"){
        //                     status = "partially win"
        //                 }else{
        //                     status = "partially lose"
        //                 }
        //             }else{
        //                 if(selectedBetCard.selectedTeam == "away"){
        //                     status = "partially win"
        //                 }else{
        //                     status = "partially lose"
        //                 }
        //             }
        //         }
        //     }
        //     if(middleSign == "-") {
        //         if(selectedBetCard.oddType == "ou"){
        //             if(selectedBetCard.selectedTeam == "under") {
        //                 status = "partially win"
        //             }else{
        //                 status = "partially lose"
        //             }
        //         }else{
        //             if(home_team_has_hadicap) {
        //                 if(selectedBetCard.selectedTeam == "home"){
        //                     status = "partially lose"
        //                 }else{
        //                     status = "partially win"
        //                 }
        //             }else{
        //                 if(selectedBetCard.selectedTeam == "away"){
        //                     status = "partially lose"
        //                 }else{
        //                     status = "partially win"
        //                 }
        //             }
        //         }
                
        //     }
        // }
        if(status == "pwin") {
            status = "partially win"
        }
        if(status == "ploss"){
            status = "partially lose"
        }
    }

    let backgroundColor = getSlipStatusBg();
  

    const chooseFont = (text) => {
        if(lang == 'zawgyi') return Rabbit.uni2zg(text);
        return text
    }
    return (
        <div>

            <div style={{ display: "flex", marginTop: "1.8rem", marginBottom: "1rem", justifyContent: "space-between" }}>
              
                <div style={{display: "flex"}}>
                        {
                            matchData?.odd?.map((o) => {
                                const selectedPeriodColor = "rgb(44 61 73)";
                                const periodBg = (o?.periods == matchTimeRange) ? selectedPeriodColor : "#0A324D";
                                return (
                                    <ButtonBase disabled={summary ? true : false} onClick={() => handleBetTimeRangeOptionClick(matchData?.matchId, o?.periods)} style={{ height: "3.5rem", display: 'inline-flex', alignItems: "center", padding: "0 2.2rem", backgroundColor: periodBg, borderRadius: "0.9rem", cursor: "pointer" ,marginRight: '1rem'}}>
                                        {inBet && dotAppear(o?.periods) && <FiberManualRecordRoundedIcon style={{ width: "0.8rem", height: "0.8rem", color: 'lightsteelblue',position: 'absolute',right: 0,top: 0 }} />}
                                        <h4 style={{ margin: 0 }} className="" >
                                            {o?.periods == "full_time" && "FT"}
                                            {o?.periods == "first_time" && "1H"}
                                            {o?.periods == "second_time" && "2H"}
                                        </h4>
                                    </ButtonBase>
                                )
                            })
                        }
                    {status &&  <div style={{display: 'flex',justifyContent: 'center',alignItems: 'center',marginLeft: "1rem"}}><div style={{padding: "0.6rem 0.8rem",backgroundColor: backgroundColor,borderRadius: "1rem",fontWeight: "bold"}}>
                                        {getSlipStatusText()}
                                </div></div>
                    }
                </div>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "end" }}>
                    <h5 style={{ margin: 0, fontWeight: 'normal', marginBottom: "0.3rem" }}>{text['Match time']}</h5>
                    <h5 style={{ margin: 0, fontWeight: 'normal' }}>{moment(matchData?.matchDate).format('DD-MM-YYYY h:mmA')}</h5>
                </div>
            </div>
            <div style={{ backgroundColor: big ? "rgb(58 59 59)" : "#0A324D", borderRadius: "1.3rem" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <ButtonBase style={{ height: "80px", borderRadius: "1.3rem 0 0 0", backgroundColor: `${(selectedBetCard?.matchId == matchData?.matchId && selectedBetCard.selectedTeam == "home" && selectedBetCard.periods == matchTimeRange) ? bettingSelectedColor : ""}`, padding: "2rem 0", display: "flex", flexDirection: "column", alignItems: "center", flexBasis: "50%", borderWidth: "0 0.5px 0 0", borderStyle: "solid", borderColor: "#4F4F4F" }} onClick={() => handleBetMatchClick({ periods: matchTimeRange, matchId: matchData?.matchId, oddType: "hdp", selectedTeam: "home", converted: hdpConverted })}>
                        <h4 style={{ margin: 0 }}>{lang != "en" ?  chooseFont(matchData?.homeTeam?.name_mm) : matchData?.homeTeam?.name_en}</h4>
                        { (!home_team_has_hadicap && !goals ) ||
                            <div style={{ marginTop: "12px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                { home_team_has_hadicap &&
                                <div style={{ width: "4.6rem", height: "2.6rem", backgroundColor: `${hdpOddColor}`, display: 'flex', justifyContent: 'center', alignItems: "center", borderRadius: "1rem", cursor: "pointer" }}>
                                    <h4 style={{ margin: 0 }} className="black">
                                        {hdpConverted}
                                    </h4>
                                </div>
                                }
                                {goals && 
                                <h3 style={{margin: 0,marginLeft: "20px"}}>{goals.home || 0}</h3>
                                }
                            </div>
                        }
                    </ButtonBase>
                    <ButtonBase disableRipple={summary ? true : false} style={{ height: "80px", borderRadius: "0 1.3rem 0 0", backgroundColor: `${(selectedBetCard?.selectedTeam == "away" && selectedBetCard?.matchId == matchData?.matchId && selectedBetCard.periods == matchTimeRange) ? bettingSelectedColor : ""}`, flexBasis: "50%", padding: "2rem 0", display: "flex", flexDirection: "column", alignItems: "center" }} onClick={() => handleBetMatchClick({ periods: matchTimeRange, matchId: matchData?.matchId, oddType: "hdp", selectedTeam: "away", converted: hdpConverted })}>
                        {summary && parlay && <ButtonBase style={{ width: "50px", height: "35px", borderRadius: "25px",position: 'absolute',top: 0,right: 0,zIndex: 900 }} sx={{ '&:hover': { opacity: "0.8" } }} onClick={() => handleOpenComfirmBox(selectedBetCard)}>
                            <ClearRoundedIcon style={{ width: "2rem", height: "2rem", color: 'lightsteelblue' }} />
                        </ButtonBase>}
                        <h4 style={{ margin: 0 ,fontFamily: "Zawgyi-One"}}>{lang != "en" ?  chooseFont(matchData?.awayTeam?.name_mm) : matchData?.awayTeam?.name_en}</h4>
                        {(home_team_has_hadicap && !goals) ||
                            <div style={{ marginTop: "12px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                {goals && 
                                <h3 style={{margin: 0,marginRight: "20px"}}>{goals.away || 0}</h3>
                                }
                                { !home_team_has_hadicap &&
                                <div style={{ width: "4.6rem", height: "2.6rem", backgroundColor: `${hdpOddColor}`, display: 'flex', justifyContent: 'center', alignItems: "center", borderRadius: "1rem", cursor: "pointer" }}>
                                    <h4 style={{ margin: 0 }} className="black">
                                        {hdpConverted}
                                    </h4>
                                </div>
                                }
                               
                            </div>
                        }
                        
                    </ButtonBase>
                </div>
                <div style={{ display: "flex", position: 'relative' }}>
                    <ButtonBase style={{ borderRadius: "0  0 0 1.3rem", backgroundColor: `${(selectedBetCard?.selectedTeam == 'over' && selectedBetCard?.matchId == matchData?.matchId && selectedBetCard.periods == matchTimeRange) ? bettingSelectedColor : ""}`, flexBasis: "50%", borderWidth: "0.5px 0.5px 0 0", borderStyle: "solid", borderColor: "#4F4F4F" }} onClick={() => handleBetMatchClick({ periods: matchTimeRange, matchId: matchData?.matchId, oddType: "ou", selectedTeam: "over", converted: ouConverted })}>
                        <h4 style={{ margin: "1.5rem 0", }}>{text.Over}</h4>
                    </ButtonBase>
                    <ButtonBase style={{ borderRadius: "0  0 1.3rem 0", backgroundColor: `${(selectedBetCard?.selectedTeam == 'under' && selectedBetCard?.matchId == matchData?.matchId && selectedBetCard.periods == matchTimeRange) ? bettingSelectedColor : ""}`, flexBasis: "50%", borderWidth: "0.5px 0 0 0", borderStyle: "solid", borderColor: "#4F4F4F" }} onClick={() => handleBetMatchClick({ periods: matchTimeRange, matchId: matchData?.matchId, oddType: "ou", selectedTeam: "under", converted: ouConverted })}>
                        <h4 style={{ margin: "1.5rem 0" }}>{text.Under}</h4>
                    </ButtonBase>
                    <div style={{ position: "absolute", left: `calc(50% - 23px)`, top: `calc(50% - 13px)`, width: "4.6rem", height: "2.6rem", backgroundColor: `${ouOddColor}`, display: 'flex', justifyContent: 'center', alignItems: "center", borderRadius: "1rem", cursor: "pointer" }}>
                        <h4 style={{ margin: 0 }} className="black">
                            {ouConverted}
                        </h4>
                    </div>
                </div>
            </div>
        </div>
    )
}

