
 /** @jsxImportSource @emotion/react */
 import { css} from '@emotion/react';
import React,{ useState ,useContext,useEffect} from 'react'
import { useNavigate } from "react-router-dom";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { getLangJson, languageContext } from '../../context'
import { Avatar, ButtonBase, ListItem, ListItemAvatar, ListItemText, Radio } from '@mui/material';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import jwtDecode from 'jwt-decode';
import {Img} from 'react-image'
import Skeleton from 'react-loading-skeleton'
import Slide from '@mui/material/Slide';

import Footer from '../footer'
import GoBackHeader from '../goBackHeader';
import Alert from '../alert';
import Loader from '../loader';
import axios from '../apiClient';

export default function Profile() {
  const navigate = useNavigate();
  const {lang} = useContext(languageContext);
  const text = getLangJson(lang);
  const [openChangeLangModal, setOpenChangeLangModal] = useState(false);
  const [height,setHeight] = useState(window.innerHeight);
  const [loading,setLoading] = useState(true)
  const [errorMsg, setErrorMsg] = useState({});
  const [openAlert, setOpenAlert] = useState(false);
  const [amount, setAmount ] = useState(0);
  const [username,setUsername] = useState('')

  // get data from backend when componenet is first mounted
  async function fetchData() {
    const token = localStorage.getItem("token");
    const {id: userId} = jwtDecode(token);

    const data = await axios.get(`/wallet/getAmount?holderId=${userId}`)

    if (data.code != 200) {
        setErrorMsg({ code: data.code, msg: data.message })
        setOpenAlert(true)
        setLoading(false)
        return
    }
    setLoading(false)
    setAmount(data.data.amount)
}
  //--------------------------------------------------
  const handleChangeLangClick = () => {
    setOpenChangeLangModal(true);
  };

  const handleChangeLangClose = () => {
    setOpenChangeLangModal(false);
  };
  const handleClick = (link) => {
    navigate(`${link}`);
  };
  window.addEventListener('resize', () => {
    setHeight(window.innerHeight)
  });
  const handleCloseAlert =()=>{
    setOpenAlert(false)
}
  useEffect(()=>{
    const token = localStorage.getItem("token");
    const {name} = jwtDecode(token);
    setUsername(name)

    fetchData()
},[])
  return (
    <div css={css`
    overflow-y: auto;
    height: calc(${height}px - 82px);
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
    ::-webkit-scrollbar { 
       display: none;  /* Safari and Chrome */
   }
  `}>
      <GoBackHeader hideArrow name={text.Profile}/>
      <Alert openAlert={openAlert} errorMsg={errorMsg} handleCloseAlert={handleCloseAlert}/>
        {loading && <Loader/>}
        {!loading &&
        <div style={{padding: "2rem 1.5rem"}}>
           <div style={{display: "flex",padding: "1.6rem 1.5rem",backgroundColor: "#0A324D",borderRadius: "1.3rem",marginBottom: "4rem"}}>
             <div style={{borderRadius: "10rem",backgroundColor: "#005853",width: "6.5rem",height: "6.5rem",display: "flex",justifyContent: "center",alignItems: "center"}}>
               <img src={`https://ui-avatars.com/api/background/?background=005853&color=fff&size=128&rounded=true&name=${username}`} style={{width: "100%",height: "100%"}}/>
             </div>
             <div style={{display: "flex",flexDirection: "column",justifyContent: "center",marginLeft: "1.5rem"}}>
               <h3 style={{margin: 0,marginBottom: "0.5rem"}} className="white">{username}</h3>
               <h3 style={{margin: 0,fontSize: "1.8rem"}} className="white">{Number(amount).toLocaleString()} {text.units}</h3>
             </div>
           </div>
           <ButtonBase component="div" style={{backgroundColor: "#0A324D",padding: "1.8rem 0",display: "flex",justifyContent: "center",alignItems: "center",borderRadius: "1.3rem",marginBottom: "3rem"}} onClick={()=> handleClick("rules")}>
              <h3 style={{margin: 0}} className="white">{text.Rules}</h3>
           </ButtonBase>

           <ButtonBase component="div" style={{backgroundColor: "#0A324D",padding: "1.8rem 0",display: "flex",justifyContent: "center",alignItems: "center",borderRadius: "1.3rem",marginBottom: "3rem"}} onClick={()=> handleClick("withdrawalRequest")}>
              <h3 style={{margin: 0}} className="white">{text['Withdrawal Request']}</h3>
           </ButtonBase>

           <ButtonBase component="div" style={{backgroundColor: "#0A324D",padding: "1.8rem 0",display: "flex",justifyContent: "center",alignItems: "center",borderRadius: "1.3rem",marginBottom: "3rem"}} onClick={()=> handleClick("withdrawalRequestLists")}>
              <h3 style={{margin: 0}} className="white">{text['Withdrawal Request Lists']}</h3>
           </ButtonBase>
        
            <ButtonBase component="div" style={{backgroundColor: "#0A324D",padding: "1.8rem 0",display: "flex",borderRadius: "1.3rem",marginBottom: "3rem"}} onClick={handleChangeLangClick}>
                <h3 style={{margin: 0}} className="white">{text['Change Language']}</h3>
            </ButtonBase>

           {openChangeLangModal ? <ChangeLangModal handleChangeLangClose={handleChangeLangClose} openChangeLangModal={openChangeLangModal}/> : ""}
           <ButtonBase component="div" style={{backgroundColor: "#0A324D",padding: "1.8rem 0",display: "flex",justifyContent: "center",alignItems: "center",borderRadius: "1.3rem",marginBottom: "3rem"}} onClick={()=> handleClick("changePassword")}>
              <h3 style={{margin: 0}} className="white">{text['Change Password']}</h3>
           </ButtonBase>

           <ButtonBase component="div" style={{backgroundColor: "#0A324D",padding: "1.8rem 0",display: "flex",borderRadius: "1.3rem",marginBottom: "3rem"}} onClick={()=>{localStorage.removeItem("token");navigate("/login")}}>
              <h3 style={{margin: 0}} className="white">{text['Log Out']}</h3>
           </ButtonBase>
        </div>
        }
      <Footer/> 
    </div>
  )
}

const ChangeLangModal = ({openChangeLangModal,handleChangeLangClose}) =>{
  
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  const {changeLang,lang} = useContext(languageContext);
  const handleListItemClick = (lang) =>{
    changeLang(lang)
    handleChangeLangClose()
  }

  return (
    <Dialog
    open={openChangeLangModal}
    TransitionComponent={Transition}
    keepMounted
    onClose={handleChangeLangClose}
    aria-describedby="alert-dialog-slide-description"
    PaperProps={{style: {backgroundColor: "#010625",width: "70%"}}}
  >
    <DialogTitle style={{color: "white",fontSize: "15px"}}>{"Change Language?"}</DialogTitle>
    <DialogContent sx={{paddingLeft: 0,paddingRight: 0}}>
       <ListItem button sx={{ color: "white" }} onClick={() => handleListItemClick("unicode")}>
            <ListItemAvatar style={{padding: "0 10px 0 0",margin: 0,width: "auto"}} >
                <Avatar style={{width: "20px",height: "20px"}} alt="Logo"  variant="rounded" >
                   <Img src={require("../../assets/flags/my.jpg")} alt="Logo" style={{objectFit: "cover",borderRadius: "4px"}} width='100%' height='100%'  loader={<Skeleton width={20} height={20} borderRadius={4}/>}/>  
                </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Unicode"
                primaryTypographyProps={{ style: { color: 'white', fontSize: "1.5rem" } }}
            />
            <Radio
                size='medium'
                sx={{ color: "white" }}
                onClick={() => handleListItemClick("unicode")}
                edge="end"
                icon={<RadioButtonUncheckedIcon sx={{ width: 20, height: 20 }} />}
                checkedIcon={<RadioButtonCheckedIcon sx={{ width: 20, height: 20, color: "#87CEEB" }} />}
                checked={(lang == "unicode")? true : false}
            />
       </ListItem>
       <ListItem button sx={{ color: "white" }} onClick={() => handleListItemClick("zawgyi")}>
            <ListItemAvatar style={{padding: "0 10px 0 0",margin: 0,width: "auto"}} >
              <Avatar style={{ width: "20px", height: "20px" }} alt="Logo" variant="rounded" >
                <Img src={require("../../assets/flags/my.jpg")} alt="Logo" style={{ objectFit: "cover", borderRadius: "4px" }} width='100%' height='100%' loader={<Skeleton width={20} height={20} borderRadius={4} />} />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Zawgyi"
                primaryTypographyProps={{ style: { color: 'white', fontSize: "1.5rem" } }}
            />
            <Radio
                size='medium'
                sx={{ color: "white" }}
                onClick={() => handleListItemClick("zawgyi")}
                edge="end"
                icon={<RadioButtonUncheckedIcon sx={{ width: 20, height: 20 }} />}
                checkedIcon={<RadioButtonCheckedIcon sx={{ width: 20, height: 20, color: "#87CEEB" }} />}
                checked={(lang == "zawgyi")? true : false}
            />
       </ListItem>
       <ListItem button sx={{ color: "white" }} onClick={() => handleListItemClick("en")}>
        <ListItemAvatar style={{padding: "0 10px 0 0",margin: 0,width: "auto"}} >
            <Avatar style={{width: "20px",height: "20px"}}  variant="rounded">
            <Img src={require("../../assets/flags/eng.jpg")} alt="Logo" style={{objectFit: "cover",borderRadius: "4px"}} width='100%' height='100%'  loader={<Skeleton width={20} height={20} borderRadius={4}/>}/>  
            </Avatar>
        </ListItemAvatar>
        <ListItemText primary="English"
            primaryTypographyProps={{ style: { color: 'white', fontSize: "1.5rem" } }}
        />
        <Radio
            size='medium'
            sx={{ color: "white" }}
            onClick={() => handleListItemClick("en")}
            edge="end"
            icon={<RadioButtonUncheckedIcon sx={{ width: 20, height: 20 }} />}
            checkedIcon={<RadioButtonCheckedIcon sx={{ width: 20, height: 20, color: "#87CEEB" }} />}
            checked={(lang == "en")? true : false}
        />
       </ListItem>
    </DialogContent>

  </Dialog>
  )
}