/** @jsxImportSource @emotion/react */
import { css} from '@emotion/react';
import React,{ useEffect, useState } from 'react'
import { Box, CircularProgress } from '@mui/material';
import jwtDecode from 'jwt-decode';

import Footer from '../footer'
import Header from '../header'
import HomeBody from './homeBody'
import axios from '../apiClient'

export default function Home() {
  const [height,setHeight] = useState(window.innerHeight);
  const [loading,setLoading] = useState(true)
  const [amount, setAmount ] = useState(0);
  window.addEventListener('resize', () => {
    setHeight(window.innerHeight)
  });


    async function getUserAmount() {
        const token = localStorage.getItem("token");
        const {id: userId} = jwtDecode(token);

        const res = await axios.get(`/wallet/getAmount?holderId=${userId}`)
        let m ;
        if (res.code != 200)  m = 0;
        m = res.data.amount
        setAmount(m)
    }
    
    useEffect(()=>{
      getUserAmount()
      setLoading(false)
    },[])

  return (
    <div  css={css`
    overflow-y: auto;
    height: calc(${height}px - 82px);
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
    ::-webkit-scrollbar { 
       display: none;  /* Safari and Chrome */
   }
  `}>    
      <Header amount={amount}/>
      {loading && <Box sx={{ display: 'flex',justifyContent: "center"}} css={css`.MuiCircularProgress-root{color: rgb(10, 50, 77);}`}><CircularProgress /></Box>}
      {!loading && <HomeBody amount={amount}/> }
      <Footer/>
    </div>
  )
}
