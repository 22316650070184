import React,{useRef, useState, useEffect} from 'react'
import {useLocation, useNavigate} from 'react-router-dom';
import {ButtonBase, Dialog, DialogContent, DialogTitle, ListItem, Slide} from '@mui/material';
import { io } from 'socket.io-client';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';

import { getLangJson, languageContext } from '../../context';
import GoBackHeader from '../goBackHeader';
import Card from "./matchCard"
import axios from '../apiClient';
import Alert from '../alert';
import Loader from '../loader';
import config from '../../config';

export default function BettedSummary() {
  const navigate = useNavigate();
  const location = useLocation();
  const {lang} = React.useContext(languageContext);

  const [loading,setLoading] = useState(false)
  const [errorMsg, setErrorMsg] = useState({});
  const [openAlert, setOpenAlert] = useState(false);
  const [openRemoveComfirmBox,setOpenRemoveComfirmBox] = useState(false);
  const [openBetComfirmBox,setOpenBetComfirmBox] = useState(false);
  const [openOddChangesWarnBox,setOpenOddChangesWarnBox] = useState(false);
  const [removeCard,setRemoveCard] = useState({});
  const [selectedCard,setSelectedCard] = useState([]);
  const [selectedMatches,setSelectedMatches] = useState([]);
  const [oddChanged,setOddChanged] = useState(false);
  const [commission,setCommisssion]= useState(0);
  const socketRef = useRef();

  const betAmount = location.state?.betAmount;
  const estimatedWin = betAmount * (2 ** selectedMatches.length);
  let text = getLangJson(lang);
  const parlay = location.state?.selectedCard?.length > 1 ;
  const single = location.state?.selectedCard?.length == 1 ;

  let disableBtn = false ;
  if(parlay && selectedCard.length < 2) disableBtn = true;
  if(single && selectedCard.length == 0) disableBtn = true;
   
  useEffect(()=>{
    getCommission()
    setSelectedCard(location.state?.selectedCard)
    setSelectedMatches(location.state?.selectedMatches)
    setOddChangeToFalse()

    socketRef.current = io(config.socketApi,{
      auth: {
          token: localStorage.getItem("token")
      },

    });
    socketRef.current.io.on("error", (error) => {
    // console.log("error from socket",error)
    });

    socketRef.current.on('oddUpdated',oddUpdated);
    socketRef.current.on('matchUpdated',matchUpdated);

    return () => {
        socketRef.current.off('oddUpdated');
        socketRef.current.disconnect();
        const idsString = localStorage.getItem('timeoutIds')
        if(idsString){
          let idsArrays = JSON.parse(idsString);
          idsArrays.forEach(id => {
              clearTimeout(id);
          });
          localStorage.setItem('timeoutIds',JSON.stringify([]));
        }
    };
  },[])

  const matchUpdated = (data) => {
    const socketData = JSON.parse(data)?.match;
    setSelectedMatches((preState)=>{
      const selectedMatches = [...preState];
      let returnData = [];
      outerLoop : for(let i=0;i<selectedMatches.length;++i){
        const localMatch = selectedMatches[i];
        for (let z = 0; z < socketData.length; z++) {
          const socketMatch = socketData[z];
          if(localMatch.matchId == socketMatch.matchId && socketMatch.status == "2H"){
            continue outerLoop;
          }else if(localMatch.matchId == socketMatch.matchId && socketMatch.status == "1H" && (localMatch.selectedPeriods == "full_time" || localMatch.selectedPeriods == "first_time")){
            continue outerLoop;
          }
        }
        returnData.push(localMatch);
      }

      return returnData;
    })

    setSelectedCard((preState)=>{
      const selectedCards = [...preState];
      let returnData = [];
      outerLoop : for(let i=0;i<selectedCards.length;++i){
        const localMatch = selectedCards[i];
        for (let z = 0; z < socketData.length; z++) {
          const socketMatch = socketData[z];
          if(localMatch.matchId == socketMatch.matchId && socketMatch.status == "2H"){
            continue outerLoop;
          }else if(localMatch.matchId == socketMatch.matchId && socketMatch.status == "1H" && (localMatch.periods == "full_time" || localMatch.periods == "first_time")){
            continue outerLoop;
          }
        }
        returnData.push(localMatch);
      }

      return returnData;
    })
  }

  const handleBetClick = () =>{
    if(oddChanged){
      setOpenOddChangesWarnBox(true);
      setOddChanged(false)
    }else{
      setOpenBetComfirmBox(true);
    }
  }

  const getCommission = async() => {
    const {selectedCard,selectedMatches} = location.state;

    let  com = 0;
    const res = await axios.get(`/info/getCommission`)
    if(res.code != 200) return 
    const {
      nine_to_eleven_mix_parlay_commission,
      single_big_match_commission,
      single_small_match_commission,
      three_to_eight_mix_parlay_commission,
      two_mix_parlay_commission
    } = res.data;

    if(selectedCard.length > 1){
      if (selectedCard.length <= 2){
        com = two_mix_parlay_commission;
      }else if(selectedCard.length <= 8 && selectedCard.length > 2){
        com = three_to_eight_mix_parlay_commission;
      }else if(selectedCard.length > 8){
        com = nine_to_eleven_mix_parlay_commission;
      }
    }else{
      const popular = selectedMatches[0].popular;
      if(popular == 0) { // it means not popular
        com = single_small_match_commission;
      }else{
        com = single_big_match_commission;
      }
    }
    setCommisssion(com)
  }

  const calculateCommission = (totolWinAmount)  => {
    if(commission == null ) return totolWinAmount
    if(single) totolWinAmount = betAmount;
     const commissionAmount = totolWinAmount  * (commission/100);
     return totolWinAmount - commissionAmount ;
  }

  const handleUserBetting = async () =>{
    setOpenOddChangesWarnBox(false)
    setOpenBetComfirmBox(false)
    setLoading(true)
    const body ={betAmount,betType: `${parlay ? "mix_parlay" : "single"}`,betData: selectedCard}
    const data = await axios.post(`/bet/setBet`,body)
    setErrorMsg({code: data.code,msg: data.message})
    setOpenAlert(true)
    setLoading(false)
    if(data.code != 200) return 
    if(parlay){
      navigate("/parlay")
    }else{
      navigate("/bodyUN")
    }
    // setTimeout(()=>{
    // },[1500])
  }

  const handleCloseAlert =()=> setOpenAlert(false);

  const handleCloseRemoveComfirmBox = () => setOpenRemoveComfirmBox(false);

  const handleCloseBetComfirmBox = () => setOpenBetComfirmBox(false);

  const handleCloseOddChangesWarnBox = () => setOpenOddChangesWarnBox(false);

  const handleOpenComfirmBox = (removedCard) => {
    setRemoveCard(removedCard)
    setOpenRemoveComfirmBox(true);
  };

  const handleOkRemove = () => {
    const updatedSelectedCard = [...selectedCard].filter(card => !(card.matchId == removeCard.matchId && card.periods == removeCard.periods));
    const updatedSelectedMatches = [...selectedMatches].filter(match => !(match.matchId == removeCard.matchId && match.selectedPeriods == removeCard.periods));
    if(updatedSelectedCard.length == 1 ){
      setErrorMsg({ code: 404, msg: `${text["Bet Match must be at least two"]}`,info: true })
      setOpenAlert(true);
      handleCloseRemoveComfirmBox();
      return
  }
    setSelectedCard(updatedSelectedCard);
    setSelectedMatches(updatedSelectedMatches);
    handleCloseRemoveComfirmBox();
  }

  const oddUpdated = (data) => {
    let localData = [];
    const socketData = data;
    
    setSelectedMatches((preState)=>{
      for (let i = 0; i < socketData.length; i++) {
            let a = []; //changed local data without "change" params
            localData = [...preState];
            if(localData.length == 0) return localData;
            const socketMatch = socketData[i];
            const {matchId: remoteMatchId,hdp: remoteHdp,ou: remoteOu,periods: remotePeriod} = socketMatch;
            const localMatches = localData;
            for (let y = 0; y < localMatches.length; y++) {
                const {matchId :localMatchId,odd: localOdds} = localMatches[y];
                if(localMatchId != remoteMatchId) continue;
                const localOdd = localOdds.find(odd=> odd.periods == remotePeriod);
                if(!localOdd) continue;
                const copylocalOdd = JSON.parse(JSON.stringify(localOdd));
                localOdd.hdp = remoteHdp;
                localOdd.ou  = remoteOu;
                a = JSON.parse(JSON.stringify(localData));
                if(remoteHdp.converted == copylocalOdd?.hdp?.converted && remoteOu.converted == copylocalOdd?.ou?.converted) continue;
                if(remoteHdp.converted != copylocalOdd?.hdp?.converted)  remoteHdp.change = true;
                if(remoteOu.converted != copylocalOdd?.ou?.converted) remoteOu.change = true;
                setOddChanged(true)
            }   
            const timeoutId = setTimeout(()=>{
                setOddChangeToFalse()
            },5000)
            let idsString = localStorage.getItem('timeoutIds')
            if(!idsString) idsString = JSON.stringify([]);
            let idsArrays = JSON.parse(idsString);
            idsArrays.push(timeoutId);
            localStorage.setItem('timeoutIds',JSON.stringify(idsArrays));
        }
        return localData
    })

  
    setSelectedCard((preState)=>{
      localData = [...preState];
      if(localData.length == 0) return localData;
      for (let i = 0; i < socketData.length; i++) {
          const socketMatch = socketData[i];
          const {matchId: remoteMatchId,hdp: remoteHdp,ou: remoteOu,periods: remotePeriod} = socketMatch;

          const localSelectedMatches = localData;
          for (let y = 0; y < localSelectedMatches.length; y++) {
              const {matchId :localMatchId,periods: localPeriods,oddType: localOddType} = localSelectedMatches[y];
              if(localMatchId != remoteMatchId) continue;
              if(localPeriods != remotePeriod) continue;
              localSelectedMatches[y].converted = socketMatch[localOddType].converted;
          } 
      }
      return localData
    })
    
  }

  const setOddChangeToFalse = () => {
    setSelectedMatches((preState) => {
      const matches = [...preState];
      for (let x = 0; x < matches.length; x++) {
        const match = matches[x];
        const odd = match?.odd[0];
        if (odd.hdp.change) odd.hdp.change = false;
        if (odd.ou.change) odd.ou.change = false;
      }
      return matches
    })
  }

  const setOddChangeToTrue = () => {
    setSelectedMatches((preState) => {
      const matches = [...preState];
      for (let x = 0; x < matches.length; x++) {
        const match = matches[x];
        const odd = match?.odd[0];
        if (odd.hdp.hasOwnProperty('change')) odd.hdp.change = true;
        if (odd.ou.hasOwnProperty('change')) odd.ou.change = true;
      }
      return matches
    })
  }

  return (
    <div>
      <GoBackHeader name={text["Bet Summary"]}/>
      <div className='white' style={{padding: "0 2rem"}}>
      <Alert openAlert={openAlert} errorMsg={errorMsg} handleCloseAlert={handleCloseAlert}/>
      {openRemoveComfirmBox ? <RemoveComfirmBox openRemoveComfirmBox={openRemoveComfirmBox} handleCloseRemoveComfirmBox={handleCloseRemoveComfirmBox} handleOkRemove={handleOkRemove}/> : ""}
      {openBetComfirmBox ? <BetComfirmBox openBetComfirmBox={openBetComfirmBox} handleCloseBetComfirmBox={handleCloseBetComfirmBox} handleUserBetting={handleUserBetting}/> : ""}
      {openOddChangesWarnBox ? <OddChangesWarmBox setOddChangeToTrue={setOddChangeToTrue} openOddChangesWarnBox={openOddChangesWarnBox} handleCloseOddChangesWarnBox={handleCloseOddChangesWarnBox} handleUserBetting={handleUserBetting}/> : ""}
      {/* <OddChangesWarmBox openOddChangesWarnBox={openOddChangesWarnBox} handleCloseOddChangesWarnBox={handleCloseOddChangesWarnBox} handleUserBetting={handleUserBetting}/> */}
         {
            selectedMatches.map((v,index)=>{
                let copyArr =selectedCard.length > 0 ? [...selectedCard] : []
                let obj = copyArr.filter(vv=>vv.matchId == v.matchId && vv.periods == v.selectedPeriods)[0]
                if(!obj) obj={};
                
                return(
                    <Card summary betTime={obj.periods} key={index} matchData={v} handleBetMatchClick={()=>{}} selectedBetCard={[obj]} handleOpenComfirmBox={handleOpenComfirmBox} parlay={parlay}/>
                )
            })
        }
      </div>
      <div style={{padding: "0 2rem 1rem 2rem",marginTop: "3rem"}} className="white">
         <div style={{display: "flex",justifyContent: "center",marginBottom: "1rem"}}>
           <h3 style={{margin: 0}}>{text['Bet Summary']}</h3>
         </div>
         <div style={{padding: "1rem",border: "1px solid grey",borderRadius: "1.3rem"}}>
           <div style={{display: "flex",justifyContent: 'space-between',margin: "1rem 0"}}>
             {single ? <h4 style={{margin: 0}}>{text.Type}</h4> :<h4 style={{margin: 0}}>{text.Parlay}</h4>}
             {single ? <h3 style={{margin: 0}}>Body</h3>  : <h3 style={{margin: 0}}>{selectedCard.length}</h3>}
           </div>
           <div  style={{display: "flex",justifyContent: 'space-between',margin: "0 0 1rem 0",paddingBottom: "1rem",borderWidth: "0 0 1px 0",borderStyle: "dotted",borderColor: "grey"}}>
             <h4 style={{margin: 0}}>{text['Betted money']}</h4>
             <h3 style={{margin: 0}}>{Number(betAmount).toLocaleString()}</h3>
           </div>
           <div  style={{display: "flex",justifyContent: 'space-between',margin: "0 0 1rem 0"}}>
             <h4 style={{margin: 0}}>{text['Estimated Win']}</h4>
             <h3 style={{margin: 0}}>{calculateCommission(estimatedWin).toLocaleString()}</h3>
           </div>
         </div>
      </div>
      <div style={{display: "flex",justifyContent: "center",alignItems:"center",padding: "0 2rem 6rem 2rem",marginTop: "4rem"}}>
        <ButtonBase disabled={loading || disableBtn} onClick={handleBetClick} component="button" style={{color: "white",display: "block",backgroundColor: "rgb(10, 50, 77)",padding: "1.6rem 0",borderRadius: "2rem",width: "100%",fontFamily: "inherit",justifyContent: "center",alignItems: "center"}}>
            {loading ? <Loader btn/>: <h4 style={{textAlign: "center",margin: 0}}>{text.Bet}</h4>}
        </ButtonBase>
      </div>
    </div>
  )
}

const RemoveComfirmBox = ({openRemoveComfirmBox,handleCloseRemoveComfirmBox,handleOkRemove}) =>{
  const {lang} = React.useContext(languageContext);
  let text = getLangJson(lang);
  
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  return (
    <Dialog
    open={openRemoveComfirmBox}
    TransitionComponent={Transition}
    keepMounted
    onClose={handleCloseRemoveComfirmBox}
    aria-describedby="alert-dialog-slide-description"
    PaperProps={{style: {backgroundColor: "#010625",width: "70%"}}}
  >
    <DialogTitle style={{color: "white",fontSize: "17px",textAlign: 'center'}}>{text['Are you sure you want to remove?']}</DialogTitle>
    <DialogContent sx={{paddingLeft: 0,paddingRight: 0}}>
      <ListItem button sx={{ color: "white",fontSize: "18px",justifyContent: 'center',padding: '1rem 0' }} onClick={() => handleOkRemove()}>
         {text.Ok}
       </ListItem>
       <ListItem button sx={{ color: "white",fontSize: "18px",justifyContent: 'center',padding: '1rem 0' }} onClick={() => handleCloseRemoveComfirmBox()}>
         {text.Cancel}
       </ListItem>
    </DialogContent>

  </Dialog>
  )
}


const BetComfirmBox = ({openBetComfirmBox,handleCloseBetComfirmBox,handleUserBetting}) =>{
  const {lang} = React.useContext(languageContext);
  let text = getLangJson(lang);

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  return (
    <Dialog
      open={openBetComfirmBox}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleCloseBetComfirmBox}
      aria-describedby="alert-dialog-slide-description"
      PaperProps={{style: {backgroundColor: "#010625",width: "70%",position: 'relative'}}}
    >
      <ButtonBase style={{ width: "50px", height: "50px", borderRadius: "25px",position: 'absolute',top: 0,right: 0,zIndex: 900 }} sx={{ '&:hover': { opacity: "0.8" } }} onClick={() => handleCloseBetComfirmBox()}>
          <ClearRoundedIcon style={{ width: "3rem", height: "3rem", color: 'lightsteelblue' }} />
      </ButtonBase>
      <DialogTitle style={{color: "white",fontSize: "20px",textAlign: 'center'}}>{text['Are you sure to bet?']}</DialogTitle>
      <DialogContent sx={{paddingLeft: 0,paddingRight: 0}}>
        <ListItem button  sx={{ color: "white",fontSize: "18px",justifyContent: 'center',padding: '1rem 0' }} onClick={() => handleUserBetting()}>
          {text.Bet}
        </ListItem>
      </DialogContent>
    </Dialog>
  )
}

const OddChangesWarmBox = ({openOddChangesWarnBox,handleCloseOddChangesWarnBox,handleUserBetting,setOddChangeToTrue}) =>{
  const {lang} = React.useContext(languageContext);
  let text = getLangJson(lang);
  
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  const handleCheckAgain  = () => {
    handleCloseOddChangesWarnBox();
    setOddChangeToTrue();
  }

  return (
    <Dialog
    open={openOddChangesWarnBox}
    TransitionComponent={Transition}
    keepMounted
    onClose={handleCloseOddChangesWarnBox}
    aria-describedby="alert-dialog-slide-description"
    PaperProps={{style: {backgroundColor: "#010625",width: "70%"}}}
  >
    <DialogTitle style={{color: "white",fontSize: "15px",textAlign: 'center'}}>{text['There is odd changes']}</DialogTitle>
    <DialogTitle style={{color: "white",fontSize: "15px",textAlign: 'center'}}>{text['Are you sure to bet?']}</DialogTitle>
    <DialogContent sx={{paddingLeft: 0,paddingRight: 0}}>
      <ListItem button sx={{ color: "white",fontSize: "18px",justifyContent: 'center',padding: '1rem 0' }} onClick={() => handleUserBetting()}>
         {text.Bet}
       </ListItem>
       <ListItem button sx={{ color: "white",fontSize: "18px",justifyContent: 'center',padding: '1rem 0' }} onClick={() => handleCheckAgain()}>
         {text['Check Again']}
       </ListItem>
    </DialogContent>

  </Dialog>
  )
}



