import React,{useState,memo, useEffect} from 'react'
import { Form, Input, Select} from 'antd';
import { LockOutlined , DollarOutlined ,CreditCardOutlined , AccountBookOutlined} from '@ant-design/icons';
import {ButtonBase, Dialog, DialogContent, DialogTitle, ListItem, Slide} from '@mui/material';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';

import GoBackHeader from '../goBackHeader'
import { getLangJson, languageContext } from '../../context';
import Alert from '../alert';
import axios from '../apiClient';
import Loader from '../loader'

export default function WithdrawalRequest() {
    const {lang} = React.useContext(languageContext); 
    const [openAlert, setOpenAlert] = useState(false);
    const [loading,setLoading] = useState(false);
    const [openOtpBox,setOpenOtpBox] = useState(false);
    const [otp,setOtp] = useState();
    const [paymentMethods,setPaymentMethods] = useState();
    const [openRequestComfirmBox,setOpenRequestComfirmbox] = useState(false);
    const [formData,setFormData] = useState();

    let text = getLangJson(lang);
    const [errorMsg, setErrorMsg] = useState({});
    const [form] = Form.useForm();

    const onFinish = async (values) => {
      setFormData(values)
      setOpenRequestComfirmbox(true);
    };

    const makeRequest = async () => {
      setOpenRequestComfirmbox(false)
      const {amount,cardNumber,paymentMethod,note} = formData;
      setLoading(true)
      const params ={
        'amount': amount,
        "cardNumber": cardNumber,
        "paymentMethod": paymentMethod,
        "note": note
      }
      const data = await axios.post('/wallet/setWithdrawRequest',params)

      let msgObj = {code: data.code,msg: data.message}
      if(data.code == 200) {
        msgObj.msg = "Successfully requested withdrawal amount";
        setOpenOtpBox(true);
        setOtp(data.data.OTP);
        form.resetFields();
      }
      setErrorMsg(msgObj)
      setOpenAlert(true)
      setLoading(false)
    }

    const onFinishFailed = (errorInfo) => {
      // console.log("Failed:", errorInfo);
    };
    const handleCloseAlert = () => setOpenAlert(false)

    const handleCloseOtpBox = () => setOpenOtpBox(false);
    const handleCloseRequestComfirmBox = () => setOpenRequestComfirmbox(false);

    const getPaymentMethod = async () => {
      const res = await axios.get(`/member/getPaymentChannelList`);
      if(res.code == 200){
        const arr = res?.data?.map(v=> {
          return {value: v?.channel_name,label: v?.display_name};
        })
        setPaymentMethods(arr);
      }
    }

    useEffect(()=>{
      getPaymentMethod();
    },[])

  return (
    <div>
      <Alert openAlert={openAlert} errorMsg={errorMsg} handleCloseAlert={handleCloseAlert}/>
      <GoBackHeader name={text["Withdrawal Request"]} />
      {openOtpBox ? <MemoOtpShow openOtpBox={openOtpBox} handleCloseOtpBox={handleCloseOtpBox} otp={otp}/> : ""}
      {openRequestComfirmBox ? <RequestComfirmBox openRequestComfirmBox={openRequestComfirmBox} handleCloseRequestComfirmBox={handleCloseRequestComfirmBox} makeRequest={makeRequest}/> : ""}
       
      <div>
        <Form
          form={form}
          name="basic"
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}
          style={{
            maxWidth: 600,
            marginTop: "2rem",
            fontFamily: "inherit"
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label={text['Amount']}
            name="amount"
            labelCol = {{span: 24}}
            wrapperCol = {{span: 24}}
            hasFeedback
            rules={[
              {
                required: true,
                message: `${text['Please input withdrawal amount!']}`,
              },
            ]}
            className="custom-input"
            style={{padding: "0 2rem",color: "white"}}
          >
            <Input
              onKeyDown={(e)=> ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
              type='number'
              prefix={<DollarOutlined className="site-form-item-icon" />} style={{backgroundColor: "rgb(10, 50, 77)"}} 
            />
          </Form.Item>
          <Form.Item
            label={text['paymentMethod']}
            labelCol = {{span: 24}}
            wrapperCol = {{span: 24}}
            name="paymentMethod"
            className="custom-input"
            hasFeedback
            rules={[
              {
                required: true,
                message: `${text['Please input payment method']}`,
              }
            ]}
            style={{padding: "0 2rem",color: "white"}}
          >
             <Select
              size='large'
              dropdownStyle={{backgroundColor: "rgb(10, 50, 77)"}}
              options={paymentMethods}
            />
            {/* <Input prefix={<CreditCardOutlined className="site-form-item-icon" />} style={{backgroundColor: "rgb(10, 50, 77)"}} className="custom-input" /> */}
          </Form.Item>
          <Form.Item
            label={text['Number']}
            labelCol = {{span: 24}}
            wrapperCol = {{span: 24}}
            name="cardNumber"
            className="custom-input"
            hasFeedback
            rules={[
              {
                required: true,
                message: `${text['Please input card number']}`,
              }
            ]}
            style={{padding: "0 2rem",color: "white"}}
          >
            <Input
               onKeyDown={(e)=> ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()} 
               type='number' prefix={<CreditCardOutlined className="site-form-item-icon" />} style={{backgroundColor: "rgb(10, 50, 77)"}} className="custom-input" 
            />
          </Form.Item>
          <Form.Item
            label={text['note']}
            labelCol = {{span: 24}}
            wrapperCol = {{span: 24}}
            name="note"
            className="custom-input"
            style={{padding: "0 2rem",color: "white"}}
          >
            <Input prefix={<AccountBookOutlined className="site-form-item-icon" />} style={{backgroundColor: "rgb(10, 50, 77)"}} className="custom-input" />
          </Form.Item>
          <div style={{display: "flex",justifyContent: "center",alignItems:"center",padding: "0 2rem",marginTop: "4rem"}}>
              <ButtonBase type='submit' component="button" style={{color: "white",display: "block",backgroundColor: "rgb(10, 50, 77)",padding: "1.6rem 0",borderRadius: "2rem",width: "100%",fontFamily: "inherit",justifyContent: "center",alignItems: "center"}}>
                  {loading? <Loader btn/> : <h4 style={{textAlign: "center",margin: 0}}>Request</h4>}
              </ButtonBase>
              
          </div>
        </Form>
      </div>
    </div>
  );
}



const OtpShow = ({openOtpBox,handleCloseOtpBox,otp}) =>{

    const {lang} = React.useContext(languageContext);
    let text = getLangJson(lang);
    
    const Transition = React.forwardRef(function Transition(props, ref) {
      return <Slide direction="up" ref={ref} {...props} />;
    });
  
    return (
      <Dialog
      open={openOtpBox}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleCloseOtpBox}
      aria-describedby="alert-dialog-slide-description"
      PaperProps={{style: {backgroundColor: "#010625",width: "70%",position: 'relative'}}}
  
    >
      <ButtonBase style={{ width: "50px", height: "50px", borderRadius: "25px",position: 'absolute',top: 0,right: 0,zIndex: 900 }} sx={{ '&:hover': { opacity: "0.8" } }} onClick={() => handleCloseOtpBox()}>
          <ClearRoundedIcon style={{ width: "3rem", height: "3rem", color: 'lightsteelblue' }} />
      </ButtonBase>
      <DialogTitle style={{color: "white",fontSize: "18px",textAlign: 'center',padding: '2rem 5rem'}}>{text['Please send this OTP to agent!']}</DialogTitle>
      <DialogContent sx={{paddingLeft: 0,paddingRight: 0}}>
        <ListItem  sx={{ color: "white",fontSize: "24px",justifyContent: 'center',padding: '1rem 0',color: 'red' }}>
           {otp}
         </ListItem>
      </DialogContent>
  
    </Dialog>
    )
}

const RequestComfirmBox = ({openRequestComfirmBox,handleCloseRequestComfirmBox,makeRequest}) =>{
  const {lang} = React.useContext(languageContext);
  let text = getLangJson(lang);

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  return (
    <Dialog
    open={openRequestComfirmBox}
    TransitionComponent={Transition}
    keepMounted
    onClose={handleCloseRequestComfirmBox}
    aria-describedby="alert-dialog-slide-description"
    PaperProps={{style: {backgroundColor: "#010625",width: "70%",position: 'relative'}}}

  >
    <ButtonBase style={{ width: "50px", height: "50px", borderRadius: "25px",position: 'absolute',top: 0,right: 0,zIndex: 900 }} sx={{ '&:hover': { opacity: "0.8" } }} onClick={() => handleCloseRequestComfirmBox()}>
        <ClearRoundedIcon style={{ width: "3rem", height: "3rem", color: 'lightsteelblue' }} />
    </ButtonBase>
    <DialogTitle style={{color: "white",fontSize: "20px",textAlign: 'center'}}>{text['Are you sure?']}</DialogTitle>
    <DialogContent sx={{paddingLeft: 0,paddingRight: 0}}>
      <ListItem button  sx={{ color: "white",fontSize: "18px",justifyContent: 'center',padding: '1rem 0' }} onClick={() => makeRequest()}>
         {text.Ok}
       </ListItem>
    </DialogContent>

  </Dialog>
  )
}

const arePropsEqual = (oldProps,newProps) => {
    const arePropsEqual =  oldProps.openOtpBox == newProps.openOtpBox && oldProps.otp == newProps.otp;

    return arePropsEqual;
}
  
const MemoOtpShow = memo(OtpShow,arePropsEqual);
