import { Avatar } from '@mui/material'
 /** @jsxImportSource @emotion/react */
 import { css} from '@emotion/react';
import React, { useState,useRef } from 'react'
import { Form, Input} from 'antd';
import {useLocation, useNavigate} from 'react-router-dom';
import { useEffect } from 'react'
import {Img} from 'react-image'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ArrowUpwardRoundedIcon from '@mui/icons-material/ArrowUpwardRounded';
import ArrowDownwardRoundedIcon from '@mui/icons-material/ArrowDownwardRounded';
import {ButtonBase} from '@mui/material';
import moment from 'moment';
import io from 'socket.io-client';

import FilterLeague from '../filterLeagues'
import { getLangJson, languageContext } from '../../../context';
import axios from '../../apiClient';
import Alert from '../../alert';
import Loader from '../../loader';
import Card from '../matchCard';
import NoData from '../../noData';
import config from '../../../config';
import { Rabbit } from '../../../rabbit';
import jwtDecode from 'jwt-decode';

export default function ParlayBody() {
    
    const navigate = useNavigate();
    const location = useLocation();
    const [amount, setAmount ] = useState(0);
    const {lang} = React.useContext(languageContext); 
    let text = getLangJson(lang);
    const [filterOpen, setFilterOpen] = useState(false)
    const [loading,setLoading] = useState(true)
    const [errorMsg, setErrorMsg] = useState({});
    const [openAlert, setOpenAlert] = useState(false);
    const [bettingMatches,setBettingMatches] = useState([])
    const [favLeagues, setFavLeagues] = useState([])
    const [leagues,setLeagues] = useState([]);
    const [filterLeagues, setFilterLeagues] = useState([])
    const [selectedBetCard,setSelectedBetCard] = useState([])
    const [minBetAmount,setMinBetAmount] = useState();
    const [maxBetAmount,setMaxBetAmount] = useState();
    const [selectedPeriod,setSelectedPeriod] = useState('FT');
    const [storedData,setStoredData] = useState([]);
    const [sortOn,setSortOn] = useState(1);
    const socketRef = useRef();

    const [height,setHeight] = useState(window.innerHeight);
  
    window.addEventListener('resize', () => {
      setHeight(window.innerHeight)
    });

    async function getUserAmount() {
        const token = localStorage.getItem("token");
        const {id: userId} = jwtDecode(token);

        const res = await axios.get(`/wallet/getAmount?holderId=${userId}`)
        let m ;
        if (res.code != 200)  m = 0;
        m = res.data.amount
        setAmount(m)
    }

    async function fetchData(leagues,leagueName) {
        setLoading(true)
        const data = await axios.get(`/bet/getBet?betType=mix_parlay&leagues=${leagues}`)

        if (data.code != 200) {
            if(data.code == 404){
                // if(leagueName){
                //     setErrorMsg({ code: data.code, msg: `${leagueName}${text["'s matches are not found"]}` })
                //     setOpenAlert(true)
                // }
                setLoading(false)
                setFilterLeagues([])
                return
            }
            setErrorMsg({ code: data.code, msg: data.message })
            setOpenAlert(true)
            setLoading(false)
            return
        }
        setBettingMatches(data.data)
        setStoredData(data.data)
        filteredSelectedPeriodMatches(selectedPeriod,data.data)
        setLoading(false)
    }

    const fetchFavLeagues = async() =>{
        const startOfToday = moment().startOf('day').format('YYYY-MM-DD HH:mm:ss');
        const endOfTomorrow = moment().add(1, 'days').endOf('day').format('YYYY-MM-DD HH:mm:ss');
        const data = await axios.get(`/info/getOddLeagueByDate?startDate=${startOfToday}&endDate=${endOfTomorrow}`)

        if (data.code != 200) {
            setLoading(false)
            if(data.code == 404) return;
            setErrorMsg({ code: data.code, msg: data.message })
            setOpenAlert(true)
            return
        }
        setFavLeagues(data.data.favLeauges)
        setLeagues(data.data.leagues)
    }

    const getAmountPerMatch = async () =>{
        const data = await axios.get(`/info/getAmountPerBet`);
        if(data.code == 200){
            setMinBetAmount(Math.round(data?.data?.mix_parlay_min_amount_per_bet))
            setMaxBetAmount(Math.round(data?.data?.mix_parlay_max_amount_per_bet))
        }
    }

    useEffect(()=>{
        getUserAmount()
        fetchFavLeagues()
        getAmountPerMatch()
        fetchData("")
        socketRef.current = io(config.socketApi,{
            auth: {
                token: localStorage.getItem("token")
            },
  
        });
        socketRef.current.io.on("error", (error) => {
        // console.log("error from socket",error)
        });

        socketRef.current.on('oddUpdated',oddUpdated);
        

        return () => {
            socketRef.current.off('oddUpdated');
            socketRef.current.disconnect();
            const idsString = localStorage.getItem('timeoutIds')
            if(idsString){
                let idsArrays = JSON.parse(idsString);
                idsArrays.forEach(id => {
                    clearTimeout(id);
                });
                localStorage.setItem('timeoutIds',JSON.stringify([]));
            }
        };
    },[])
    
    const oddUpdated = (data) => {
        let localData = [];
        let a = []; //changed local data without "change" params
        const socketData = data;

        setBettingMatches((preState)=>{
            localData = [...preState];
            if(localData.length == 0) return localData;
            for (let i = 0; i < socketData.length; i++) {
                const socketMatch = socketData[i];
                const {matchId: remoteMatchId,hdp: remoteHdp,ou: remoteOu,periods: remotePeriod} = socketMatch;
                for (let z = 0; z < localData.length; z++) { // loop the league
                    const {match: localMatches} = localData[z];
                    for (let y = 0; y < localMatches.length; y++) { // loop the matches
                        const {matchId :localMatchId,odd: localOdds} = localMatches[y];
                        if(localMatchId != remoteMatchId) continue;
                        const localOdd = localOdds.find(odd=> odd.periods == remotePeriod);
                        if(!localOdd) continue;
                        const copylocalOdd = JSON.parse(JSON.stringify(localOdd));
                        localOdd.hdp = remoteHdp;
                        localOdd.ou  = remoteOu;
                        a = JSON.parse(JSON.stringify(localData));
                        if(remoteHdp.converted == copylocalOdd?.hdp?.converted && remoteOu.converted == copylocalOdd?.ou?.converted) continue;
                        if(remoteHdp.converted != copylocalOdd?.hdp?.converted) remoteHdp.change = true;
                        if(remoteOu.converted != copylocalOdd?.ou?.converted) remoteOu.change = true;   
                    }  
                }
                const timeoutId = setTimeout(()=>{
                    setBettingMatches((preState)=> {
                        const leagues = [...preState];
                        for (let x = 0; x < leagues.length; x++) { // loop the leagues
                            const league = leagues[x];
                            const matches = league.match;
                            for (let y = 0; y < matches.length; y++) { // loop the matches
                                const match = matches[y];
                                const odds = match.odd;
                                for (let y = 0; y < odds.length; y++) { // loop the odds
                                    const odd = odds[y];
                                    if(odd.hdp.change) odd.hdp.change = false;
                                    if(odd.ou.change) odd.ou.change = false;
                                }  
                            }                           
                        }
                        return leagues
                    })
                },5000)
                let idsString = localStorage.getItem('timeoutIds')
                if(!idsString) idsString = JSON.stringify([]);
                let idsArrays = JSON.parse(idsString);
                idsArrays.push(timeoutId);
                localStorage.setItem('timeoutIds',JSON.stringify(idsArrays));
            }
            return localData
        })

        setSelectedBetCard((preState)=>{
            localData = [...preState];
            if(localData.length == 0) return localData;
            for (let i = 0; i < socketData.length; i++) {
                const socketMatch = socketData[i];
                const {matchId: remoteMatchId,hdp: remoteHdp,ou: remoteOu,periods: remotePeriod} = socketMatch;
      
                const localSelectedMatches = localData;
                for (let y = 0; y < localSelectedMatches.length; y++) {
                    const {matchId :localMatchId,periods: localPeriods,oddType: localOddType} = localSelectedMatches[y];
                    if(localMatchId != remoteMatchId) continue;
                    if(localPeriods != remotePeriod) continue;
                    localSelectedMatches[y].converted = socketMatch[localOddType].converted;
                } 
            }
            return localData
        })
 
    }

    const hanldeFilterLeaguesClick =(e)=>{
       setFilterOpen(true)
       let serverData = [...leagues];
        for (let i = 0; i < filterLeagues.length; i++) {
            const v = filterLeagues[i];
            const index = serverData.findIndex(vv => vv.id == v);
            const copyIndexObj = {...serverData[index]};
            serverData.splice(index, 1); // remove selected element
            serverData.unshift(copyIndexObj); // add selected elemet to the top of the array
        }
        setLeagues(serverData)
    }

    const hanldeFilterLeaguesClose =(e)=>{
        setFilterOpen(false)
    }

    const handleFavLeaguesClick = (league) =>{
        const leagueId = league.id
        // let copyArr = [...filterLeagues];
        let copyArr = [];
        (filterLeagues.includes(leagueId))? copyArr = filterLeagues.filter(v=>v != leagueId) : copyArr.push(leagueId)
        setFilterLeagues(copyArr)
        handleFilterMatchesByLeagues(copyArr,league)
    }

    const handleFilterMatchesByLeagues = (selectedLeagues,selectedLeague) =>{
        let leagueName;
        if(lang != 'en') leagueName = selectedLeague.league_name_mm
        if(lang == 'en') leagueName = selectedLeague.league_name_en
        let str = "" ;
        for (let i = 0; i < selectedLeagues.length; i++) {
            const v = selectedLeagues[i];
            if(i +1 == selectedLeagues.length){
                str += v 
            }else{
                str += v + ","
            }
        }
        fetchData(str,leagueName)
        setFilterLeagues(selectedLeagues)
    }

    const handleBetMatchClick = (clickedCard) =>{
        const copyArr = selectedBetCard.length > 0 ? [...selectedBetCard] : []
        const sameBoxIndex =copyArr.findIndex(v => v.matchId == clickedCard.matchId && v.periods == clickedCard.periods && v.oddType == clickedCard.oddType && v.selectedTeam == clickedCard.selectedTeam);
        // remove if user clicked same box
        if(sameBoxIndex > -1) return setSelectedBetCard(copyArr.filter((v,index) => index != sameBoxIndex))
        
        const index = copyArr.findIndex(v => v.matchId == clickedCard.matchId && v.periods == clickedCard.periods)
        if(index > -1) copyArr[index] = clickedCard; // update if user clicked same card
        if(index == -1) copyArr.push(clickedCard) // insert if user clicked new card
        setSelectedBetCard(copyArr)
    }


    const onFinish = ({betAmount}) => {
        const selectedMatches = [];
        for (let i = 0; i < selectedBetCard.length; i++) {
            const v = selectedBetCard[i];
            for (let vv = 0; vv < bettingMatches.length; vv++) {
                const {match: matches} = bettingMatches[vv];
                for (let vvv = 0; vvv < matches.length; vvv++) {
                    const match = {...matches[vvv]};
                    if(match.matchId == v.matchId) {
                        match.selectedPeriods = v.periods;
                        selectedMatches.push(match);
                    }
                }  
            }
        }
        if(selectedBetCard.length == 1 ){
            setErrorMsg({ code: 404, msg: `${text["Bet Match must be at least two"]}`,info: true })
            setOpenAlert(true)
            return
        }
        if(betAmount > amount ){
            setErrorMsg({ code: 404, msg: `${text["You don't have sufficient balance to make this bet"]}`,info: true })
            setOpenAlert(true)
            return
        }
        const obj = {selectedMatches,selectedCard: selectedBetCard,betAmount} 
        navigate('/bettedSummary',{state: obj});
    };

    const onFinishFailed = (errorInfo) => {
    //   console.log("Failed:", errorInfo);
    };

    const handleCloseAlert =()=>{
        setOpenAlert(false)
    }

    const chooseFont = (text) => {
        if(lang == 'zawgyi') return Rabbit.uni2zg(text);
        return text
    }
    const handlePeriodClick = (period) => {
        setSelectedPeriod(period);
       filteredSelectedPeriodMatches(period,storedData)
    }

    const filteredSelectedPeriodMatches = (period,matches) => {
        let copyBettingMatches =  JSON.parse(JSON.stringify(matches)); 
        let y = []; // leagues array
        for (let l = 0; l < copyBettingMatches.length; l++) { //loop the leagues
            let z = []; // matches array
            let matches = copyBettingMatches[l].match;
            for (let m = 0; m < matches.length; m++) { // loop the matches
                const match = matches[m];
                if(period == "FT")  match.odd = match.odd.filter(o => o.periods == "full_time");
                if(period == "1H") match.odd = match.odd.filter(o => o.periods == "first_time");
                if(match.odd.length == 0 ) continue;
                z.push(match);
            }
            copyBettingMatches[l].match = z;
            if(z.length == 0) continue;
            y.push(copyBettingMatches[l]);
        }
        copyBettingMatches = y;
        setBettingMatches(copyBettingMatches)
        setSelectedBetCard([]);
    }

    const selectedPeriodColor = "rgb(14, 82, 77)";
    const periodColor = "rgb(10, 50, 77)";

    const sortMatchesByDate = () => {
        setSortOn(pre => {
            let sort;
            if(pre == 1) sort = 2;
            if(pre == 2) sort = 3;
            if(pre == 3) sort = 1;
            if(sort == 2 || sort == 3){
                let arr = [{
                    league: {id: null},
                    match: [
        
                    ]
                }]
                bettingMatches.forEach(v=> 
                    v.match.forEach(vv => 
                        arr[0].match.push(vv)
                    )
                )
                if(sort == 2) arr[0].match.sort((a,b) => new Date(b.matchDate) - new Date(a.matchDate));
                if(sort == 3) arr[0].match.sort((a,b) => new Date(a.matchDate) - new Date(b.matchDate));
                setBettingMatches(arr)                
            }else{
                fetchData("");
            }
            return sort;
        });

    }


  
  return (
    <div css={css`
    overflow-y: auto;
    height: calc(${height}px - ${selectedBetCard.length > 0 ? "210px" : "0px"});
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
    ::-webkit-scrollbar { 
       display: none;  /* Safari and Chrome */
   }
  `}>
        {loading && <Loader bet/>}
        {!loading &&
        <div>
            <Alert openAlert={openAlert} errorMsg={errorMsg} handleCloseAlert={handleCloseAlert}/>
            <div style={{display: "flex",padding: '1rem'}}>
                <div style={{display: "flex",flexDirection: "row",justifyContent: "space-evenly",flexBasis: "70%",alignItems: 'center'}}>
                  {/* {favLeagues?.map((v,index) => {
                    return (
                        <ButtonBase key={index}  style={{borderRadius: "50px"}}>
                            <Avatar alt="Logo"   sx={{borderColor: "red",borderWidth: `${filterLeagues.includes(v.id)? '2px': "0px"}`,borderStyle: 'solid',cursor: "pointer"}} onClick={()=>handleFavLeaguesClick(v)}>
                            <Img src={[v.logo,config.defaultLogo]} style={{objectFit: "cover",borderRadius: "50px"}} width='100%' height='100%'  loader={<Skeleton width={40} height={40} circle/>}/>  
                            </Avatar>
                        </ButtonBase>
                    )
                  })} */}
                   <ButtonBase  onClick={()=>handlePeriodClick('FT')} style={{ height: "3.5rem", display: 'inline-flex', alignItems: "center", padding: "0 2.2rem", backgroundColor: selectedPeriod == "FT" ? selectedPeriodColor : periodColor, borderRadius: "0.9rem", cursor: "pointer" ,marginRight: '1rem'}}>             
                        <h4 style={{ margin: 0 }} className="" >
                            FT
                        </h4>
                    </ButtonBase>
                    <ButtonBase  onClick={()=>handlePeriodClick('1H')} style={{ height: "3.5rem", display: 'inline-flex', alignItems: "center", padding: "0 2.2rem", backgroundColor: selectedPeriod == "1H" ? selectedPeriodColor : periodColor, borderRadius: "0.9rem", cursor: "pointer" ,marginRight: '1rem'}}>             
                        <h4 style={{ margin: 0 }} className="" >
                            1H
                        </h4>
                    </ButtonBase>
                </div>
                <ButtonBase style={{display: 'flex',alignItems: "center",padding: "1rem 2rem 1rem 2rem",backgroundColor: "#0A324D",borderRadius: "0.9rem",marginLeft: "2rem",cursor: "pointer",fontFamily: "inherit"}} onClick={sortMatchesByDate}>
                    <h3 style={{margin: 0}}>{text.Sort}</h3>
                    {sortOn == 2 ? <ArrowUpwardRoundedIcon style={{width: "3.6rem",height: "2rem",borderRadius: "3rem",marginTop: 0}}/> : sortOn == 3 ? <ArrowDownwardRoundedIcon style={{width: "3.6rem",height: "2rem",borderRadius: "3rem",marginTop: 0}}/>: ""}
                </ButtonBase>
                <ButtonBase style={{display: 'flex',alignItems: "center",padding: "1rem 0 1rem 1rem",backgroundColor: "#0A324D",borderRadius: "0.9rem",marginLeft: "2rem",flexBasis: "30%",cursor: "pointer",fontFamily: "inherit"}} onClick={hanldeFilterLeaguesClick}>
                    <h3 style={{margin: 0}}>{text["Leagues"]}</h3>
                    {filterOpen ? <ExpandLess style={{width: "3.6rem",height: "2rem",borderRadius: "3rem",marginTop: 0}}/> : <ExpandMore style={{width: "3.6rem",height: "2rem",borderRadius: "3rem",marginTop: 0}}/>}
                </ButtonBase>
                {filterOpen && <FilterLeague leaguesArr={leagues} filterLeagues={filterLeagues} handleFilterMatchesByLeagues={handleFilterMatchesByLeagues} hanldeFilterLeaguesClose={hanldeFilterLeaguesClose} open ={filterOpen} />}
            </div>
            {/* <div style={{display: "flex",padding: '1rem',justifyContent: "end"}}>
                
            </div> */}
            <div style={{padding: "2rem 2rem"}}>
                {  bettingMatches.length == 0 ? 
                    <NoData msg={text['There is no bet match']} height="60vh"/> :
                    bettingMatches.map(({league,match: matches},index)=>{
                        return (
                            <div key={index} style={{marginBottom: "3rem"}}>
                                {
                                    league.id && 
                                    <div style={{display: "flex",alignItems: 'center'}}>
                                    <Avatar style={{objectFit: "cover",width: "3.6rem",height: "3.6rem",borderRadius: "3rem"}}>
                                        <Img src={[league.logo,config.defaultLogo]} style={{objectFit: "cover",width: "3.6rem",height: "3.6rem",borderRadius: "3rem"}} loader={<Skeleton width={36} height={36} circle/>}/>  
                                    </Avatar>
                                    <h3 style={{marginLeft: "0.8rem"}}>{lang != "en"? chooseFont(league.name_mm) : league.name_en}</h3>
                                    </div>
                                }
                                {
                                    matches.map((matchData,index)=>{
                                        let copyArr =selectedBetCard.length > 0 ? [...selectedBetCard] : []
                                        let arr = copyArr.filter(v=>v.matchId == matchData.matchId );
                                        if(!arr) arr=[];
                    
                                        return(
                                            <Card key={matchData.matchId} matchData={matchData} handleBetMatchClick={handleBetMatchClick} selectedBetCard={arr} inBet/>
                                        )
                                    })
                                }
                            </div>
                        )
                    })
                }
            </div>
            {
                selectedBetCard.length > 0 && 

                
                <div style={{width: "390px",position: "fixed",bottom: 0,left: 0,right: 0,zIndex: 1000,marginLeft: "auto",marginRight: "auto"}}>
                    <div style={{padding: "2rem 2rem",backgroundColor: "#021928"}}>
                       
                          <Form
                              name="basic"
                              style={{
                                  fontFamily: "inherit",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between"
                              }}
                              initialValues={{
                                  remember: true,
                              }}
                              className="bet-amount"
                              onFinish={onFinish}
                              onFinishFailed={onFinishFailed}
                              autoComplete="off"
                          >
                              <Form.Item
                
                                  name="betAmount"
                                  className="custom-input"
                                  hasFeedback
                                  rules={[
                                      ({ getFieldValue }) => ({
                                        
                                        validator(_, value) {
                                          if ( minBetAmount <= value && value <= maxBetAmount) {
                                            return Promise.resolve();
                                          }
                                          return Promise.reject(new Error(`${text['Bet Amount must be between']} ${minBetAmount} ${text['And']} ${maxBetAmount} ${text['!']}`));
                                        },
                                      }),
                                  ]}
                                  style={{ padding: "0", color: "white" , flexBasis: "70%"}}
                              >
                                  <Input 
                                    onBlur={()=> window.scrollTo(0,0)}
                                    onKeyDown={(e)=> ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()} 
                                    type='number' placeholder={text['Enter the amount']} style={{ backgroundColor: "black" }} className="custom-input" 
                                  />
                              </Form.Item>
                               <ButtonBase disabled={selectedBetCard.length == 0  && true} type='submit' component="button" style={{ color: "black", backgroundColor: "rgb(135, 206, 235)", padding: "1rem 1.6rem", borderRadius: "1rem", fontFamily: "inherit"}} >
                                   <h4 style={{margin: 0 }}>{text.Bet}</h4>
                               </ButtonBase>
                
                          </Form>
                        <div >
                            <div style={{display: "flex",justifyContent: "space-between"}}>
                                <h5 style={{margin: 0,padding: "2rem 0 0 0", fontWeight: "normal",color: "rgb(140, 137, 137)"}}>
                                    {text.Parlay}
                                </h5>
                                <h4 style={{margin: 0,padding: "2rem 0 0 0",fontWeight: "normal",color: "rgb(202, 197, 197)"}}>
                                   {selectedBetCard.length > 0 ? selectedBetCard.length : "-"}
                                </h4>
                            </div>
                            <div style={{display: "flex",justifyContent: "space-between"}}>
                                <h5 style={{margin: 0,padding: "2rem 0 0 0", fontWeight: "normal",color: "rgb(140, 137, 137)"}}>
                                    {text.Balance}
                                </h5>
                                <h4 style={{margin: 0,padding: "2rem 0 0 0",fontWeight: "normal",color: "rgb(202, 197, 197)"}}>
                                    {Number(amount).toLocaleString()}
                                </h4>
                            </div>
                
                        </div>
                    </div>
                </div>
            }
        </div>
    }
    </div>
  )
}



