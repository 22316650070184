 /** @jsxImportSource @emotion/react */
import { css} from '@emotion/react';
import { useState, useRef ,useEffect } from 'react'
import {useLocation, useNavigate} from "react-router-dom"
import { useContext, useLayoutEffect } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component';
import ButtonBase from '@mui/material/ButtonBase';
import {Img} from 'react-image'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import Footer from '../footer'
import { getLangJson, languageContext } from '../../context'
import GoBackHeader from '../goBackHeader'
import axios from '../apiClient'
import Alert from '../alert'
import moment from 'moment';
import Loader from '../loader';
import NoData from '../noData';
import RenderIfVisible from 'react-render-if-visible';
import { FixedSizeList, VariableSizeList } from "react-window";


export default function News() {
  const navigate = useNavigate();
  const location = useLocation();
  const containerRef = useRef(null);
  const listRef = useRef(null);
  const rowHeights = useRef(null);
  const {lang} = useContext(languageContext);
  const text = getLangJson(lang);
  const [errorMsg, setErrorMsg] = useState({});
  const [openAlert, setOpenAlert] = useState(false);
  const [loading,setLoading] = useState(true)
  const [hasMore,setHasMore ] =useState(true)
  const [height,setHeight] = useState(window.innerHeight);
  const [news,setNews] = useState([]);
  const [scrollPos,setScrollPos] = useState();


  async function fetchData(fetchMore,search) {
    let from;let to;
    if(fetchMore){
      from = news.length;
      to   = news.length + 10;
    }else{
      from = 0;
      to   = 10;
    }

    const res = await axios.get( `/meida/getMediaInfos?s=${from}&c=${to}`)

    if(res.code != 200){
      if(res.code == 404){
        setLoading(false)
        if(fetchMore){
            setHasMore(false)
        }else{
          setNews([])
        }
        return;
      } 
      setErrorMsg({code: res.code,msg: res.message})
      setOpenAlert(true)
      return
    }
    let combineArr;
    if(search){
      combineArr = [...res.data.result]
    }else{
      combineArr = [...news,...res.data.result]
    }
    setNews(combineArr)
    setLoading(false)
  }

  useEffect(()=>{
    const cacheFound = localStorage.getItem('cache');
    if(cacheFound){
      const p = JSON.parse(cacheFound);
      const {news,scrollPosition} = p;  
      setTimeout(()=>{
        containerRef.current.scrollTo({
            top: scrollPosition,
            behavior: 'smooth',
          });
      },0.00001)
      setNews(news)
      setLoading(false)
    }else{
      fetchData();
    }
    },[])



  function handleScroll(event) {
    setScrollPos(event.target.scrollTop)
  }


  const fetchMoreData = ()=>{
    console.log("fetch more")
    fetchData(true)
  }
  const handleSearch = async (e) =>{
 
    const data = await axios.get( `/meida/searchMediaByTitle?st=${e.currentTarget.value}`)

    if(data.code == 404){
      setNews([]);
      return;
    }
    if(data.code == 200){
      setNews(data.data)
      return
    }
    setErrorMsg({code: data.code,msg: data.message})
    setOpenAlert(true)
  }

  const handleCloseAlert =()=>{
    setOpenAlert(false)
  }
  window.addEventListener('resize', () => {
    setHeight(window.innerHeight)
  });
  const handleNewsDetails=(newsDetails)=>{
    const c =  {news,scrollPosition: scrollPos};
    const s = JSON.stringify(c);
    localStorage.setItem("cache",s)
    navigate("/newsDetails",{state: {news,newsDetails,scrollPosition: scrollPos}})
  }

  return (
    <div>
       <Alert openAlert={openAlert} errorMsg={errorMsg} handleCloseAlert={handleCloseAlert}/>
      <GoBackHeader hideArrow name={text.News}/>
      {loading && <Loader/>}
      {!loading && 
      
      <div >
        <div style={{padding: "2rem 1.5rem"}} >
            <div style={{padding: "1.3rem 2.7rem",backgroundColor: "#0A324D",borderRadius: "2.7rem",marginBottom: "2rem"}}>
              <input css={css`::placeholder{font-size: 1.4rem !important}`} autoComplete='off' onChange={handleSearch} placeholder={text['Search2...']} id='news-search' style={{border: 0,backgroundColor: "#0A324D",outline: "none",color: "white",fontWeight: "normal",fontSize: "1.6rem",height: "1.4rem",padding: '0.5rem',fontFamily: 'myanmar'}}/>
            </div>
          {news.length == 0 ? <NoData msg={text['There is no news']} height="60vh"/> : 
          <div
          id="news"
          css={css`
          overflow-y: scroll;
          height: calc(${height}px - 220px);
          -ms-overflow-style: none;  /* Internet Explorer 10+ */
          scrollbar-width: none;  /* Firefox */
          ::-webkit-scrollbar {
            display: none !important;  /* Safari and Chrome */
          }
        `}
        onScroll={handleScroll}
        ref={containerRef}
          >
            <InfiniteScroll
              css={css`
              -ms-overflow-style: none;  /* Internet Explorer 10+ */
              scrollbar-width: none;  /* Firefox */
              ::-webkit-scrollbar {
               display: none !important;  /* Safari and Chrome */
              }
             `}
              dataLength={news.length} //This is important field to render the next data
              next={fetchMoreData}
              hasMore={hasMore}
              loader={ <Loader btn/>}
              scrollableTarget="news"
              endMessage={
                <p style={{ textAlign: 'center',color: 'white' }}>
                  <b style={{color: 'white' }}>End</b>
                </p>
              }
            >
               {news.map((news,index)=>{
                  return (
                    <div key={index}>
                      <Card news={news} handleNewsDetails={()=>handleNewsDetails(news)}/>
                    </div>
                  )
                })}

      
            </InfiniteScroll>
          </div>
          }
        
        </div>
      </div>
      }
      <Footer/>
    </div>
  )
}
function limitText(text, count){
  return text.slice(0, count) + (text.length > count ? "..." : "");
}
function Card({news,handleNewsDetails}) {
  const formattedDate = moment(news.createdAt).format('MMM DD,YYYY');

  return (

    <ButtonBase component="div" style={{display: "block"}} onClick={handleNewsDetails} >
    <div style={{padding: "1.8rem 2rem",backgroundColor: "#0A324D",borderRadius: "2.6rem",display: "flex",marginBottom: "0.9rem"}} >
      <div style={{width: '100px',height: '90px'}}>
        <Img width='100px' height='90px' style={{borderRadius: "0.7rem",objectFit: "cover"}} src={news.feature_img} loader={<Skeleton width={106} height={80} borderRadius="0.7rem"/>}  unloader={<Skeleton width={106} height={80} borderRadius="0.7rem"/>} />
      </div>
      <div style={{display: "flex",flexDirection: "column",marginLeft: "1.2rem"}}>
         <h5 style={{margin: 0,fontWeight: "normal",marginBottom: "1rem"}} className="white">{limitText(news.title,68)}</h5>
         <h5 style={{margin: 0,fontWeight: "normal",marginBottom: "1rem",fontSize: '11px',color: 'rgb(135, 206, 235)'}} className="white">{news.news_type}</h5>
         <h6 style={{margin: 0,fontWeight: "normal",color: '#cdcdcd'}} className="white">{formattedDate}</h6>
      </div>
    </div>
    </ButtonBase>

  )
}




