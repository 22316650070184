 /** @jsxImportSource @emotion/react */
 import { css} from '@emotion/react';
import React ,{ useState , useEffect, useRef, useLayoutEffect } from 'react'
import { ButtonBase, Tab, Tabs} from '@mui/material'
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { IconButton } from '@mui/material';
import jwt_decode from "jwt-decode";
import axios from '../apiClient'
import moment from 'moment';
import { json, useLocation, useNavigate } from "react-router-dom";
import {Img} from 'react-image'
import Skeleton from 'react-loading-skeleton'

import GoBackHeader from '../goBackHeader'
import { useContext } from 'react'
import { getLangJson, languageContext } from '../../context'
import Alert from '../alert'
import Loader from '../loader'
import NoData from '../noData';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Avatar, Badge } from 'antd';
import config from '../../config';


export default function BettedHistory() {
    const monthLabels = [{en: 'Jan',my: "ဇန်နဝါရီ"},{ en: 'Feb',my: "ဖောဖေါ်ဝါရီ"},{en: 'Mar',my: "မတ်"},{en: 'Apr',my: "ဧပရယ်"},{en: 'May',my: "မေ"},{en: 'Jun',my: "ဇွန်"},{en: 'Jul',my: "ဇူလိုင်"},{en: 'Aug',my: "သြဂုတ်"},{en: 'Sep',my: "စက်တင်ဘာ"},{en: 'Oct',my: "အောက်တိုဘာ"},{en: 'Nov',my: "နိုဝင်ဘာ"},{en: 'Dec',my: "ဒီဇင်ဘာ"}];
    const {lang} = useContext(languageContext);
    const text = getLangJson(lang);
    const [loading,setLoading] = useState(true)
    const [data,setData] = useState([]);
    const [errorMsg, setErrorMsg] = useState({});
    const [days,setDays] = useState([])
    const [openAlert, setOpenAlert] = useState(false);
    const [filterDateOpen,setFilterDateOpen] = useState(false)
    const [months, setMonths] = useState([]);
    const cacheSelectedMonth = localStorage.getItem('cache') ? JSON.parse(localStorage.getItem('cache')).selectedMonth : getToday();
    const cacheSelectedDay = localStorage.getItem('cache') ? JSON.parse(localStorage.getItem('cache')).selectedDay : {value: moment().format('YYYY-MM-DD'),label: "Today"};
    const [selectedMonth, setSelectedMonth] = useState(cacheSelectedMonth);
    const [selectedDay, setSelectedDay] = useState(cacheSelectedDay);
    const [hasMore,setHasMore ] =useState(true)
    const containerRef = useRef(null);
    const [height,setHeight] = useState(window.innerHeight);
    const didMount = useRef(false);

    useEffect(()=>{
        
        if( !didMount.current){ // only call once
            getLast30Days();
            const cacheFound = localStorage.getItem('cache'); 
            if(cacheFound){
                const p = JSON.parse(cacheFound);
                setLoading(false)
                const {selectedMonth, selectedDay, scrollPosition,slips,months} = p;
                setMonths(months)
                if(slips) setData(slips)
                setTimeout(()=>{
                    // containerRef.current.scrollTo(0,scrollPosition)
                    containerRef?.current?.scrollTo({
                        top: scrollPosition || 0,
                        behavior: 'smooth',
                      });
                },0.00001)
                setLoading(false)
            }else{
                fetchData(false,true)
                getLast5months();
            }
            didMount.current = true;
            changeLocalStorage('selectedDay',cacheSelectedDay)
        }else{
            fetchData(false,true)
        }
    },[selectedDay])

    window.addEventListener('resize', () => {
        setHeight(window.innerHeight)
    });

    const getLast30Days = () =>{
        const dates = [];
        const currentDate = moment();
    
        // Add the last 7 days
        for (let i = 29; i >= 0; i--) {
          const obj = {};
          obj.value = currentDate.clone().subtract(i, 'days').format('YYYY-MM-DD');
          if (currentDate.isSame(obj.value, 'day')){
            obj.label = "Today"
          }else{
            obj.label = capitalizeFirstLetter(currentDate.clone().subtract(i, 'days').format('MMM D'));
          }
          dates.push(obj)
        }

        setDays(dates);
    
      }

    async function fetchData(fetchMore,updatedDate) {
        if(!fetchMore) setLoading(true);
        const token = localStorage.getItem("token");
        const {id: userId} = jwt_decode(token);
        let from;let to; let c = 10;
        if(fetchMore){
            from = data.length;
            to   = data.length + c;
        }else{
            from = 0;
            to   = c;
        }
        const {startDate,endDate} = getStartDateAndEndDate();
        const res = await axios.get(`/history/getSlipByMonth?version=1.1&memberId=${userId}&startDate=${startDate}&endDate=${endDate}&s=${from}&c=${to}`)

        if (res.code != 200) {
            setLoading(false)
            if(res.code == 404) {
                if(fetchMore) return setHasMore(false)
                changeLocalStorage('slips',[])
                return setData([])
            }
            setErrorMsg({ code: res.code, msg: res.message })
            setOpenAlert(true)
            return
        }
        let combineArr;
   
        if(updatedDate){
            combineArr = [...res.data]
        }else{
            combineArr = [...data,...res.data]
        }
        setLoading(false)
        setData(combineArr)
        changeLocalStorage('slips',combineArr)
    }

    function getStartDateAndEndDate(){
        const inputDate = moment(selectedDay.value, 'YYYY-MM-DD');
        const startDate = inputDate.clone().startOf('day').format('YYYY-MM-DD HH:mm:ss');
        const endDate = inputDate.clone().endOf('day').format('YYYY-MM-DD HH:mm:ss');
        return { startDate,endDate}
    }

    function getMonthLabel(monthNameShortForm) {
        let v = "";
        for (let i = 0; i < monthLabels.length; i++) {
            const label = monthLabels[i];
            if(label.en == monthNameShortForm){
                v = (lang == 'unicode' || lang == 'zawgyi') ? label.my : label.en
            }
        }
        return v;
    }

    function getToday() {
        const currentDate = moment();
        const obj = {};
        obj.value = currentDate.clone().format('YYYY-MM-DD');
        let monthName = capitalizeFirstLetter( currentDate.clone().format('MMM'));
        obj.label = getMonthLabel(monthName);
        return obj
    }

    const hanldeFilterDateClick = ()=> filterDateOpen ? setFilterDateOpen(false) : setFilterDateOpen(true);

    const getLast5months = () =>{
        const months = [];
        const currentDate = moment();
    
        // Add the last 5 months
        for (let i = 4; i >= 0; i--) {
          const obj = {};
          obj.value = currentDate.clone().subtract(i, 'months').format('YYYY-MM-DD');
          let monthName = capitalizeFirstLetter( currentDate.clone().subtract(i, 'months').format('MMM'));
          obj.label = getMonthLabel(monthName);
          months.push(obj)
        }
        setMonths(months);
    }

    const handleMonthChange = (month) => {
        setSelectedMonth(month)
        hanldeFilterDateClick()
    };

    const handleDayChange = (day) => {
        changeLocalStorage('selectedDay',day)
        setSelectedDay(day)
        hanldeFilterDateClick()
    };

    const handleCloseAlert =()=> setOpenAlert(false)

    const fetchMoreData = ()=> fetchData(true);

    const changeLocalStorage = (key, value) => {
        let cache = localStorage.getItem("cache");
        let c;
        if(cache){
            c = JSON.parse(cache);
        }else{
            c = {};
        }
        const m = {...c,[key]: value};
        const s = JSON.stringify(m);
        localStorage.setItem("cache",s);
    }
    

    function handleScroll(event) {
        const c = {slips: data,months,selectedMonth,selectedDay,scrollPosition: event.target.scrollTop};
        const s = JSON.stringify(c);
        localStorage.setItem("cache",s);
    }

  return (
    <div >
        <GoBackHeader name={text['Betted History']} />
        {loading && <Loader/>}
        {!loading && 
        <div style={{margin: "0 0 0 0"}} >
          <Alert openAlert={openAlert} errorMsg={errorMsg} handleCloseAlert={handleCloseAlert}/>
            <div style={{display: 'flex',flexDirection: "column",margin: "1rem 0",padding: '0 2rem'}}>
                <div style={{display: 'flex',justifyContent: "end"}}>
                    <ButtonBase   component="div" style={{display: "flex",justifyContent: "center",alignItems: "center",padding: "1rem 0 1rem 1rem",backgroundColor: "#0A324D",borderRadius: `${filterDateOpen? "0.9rem 0.9rem 0 0" : "0.9rem"}`,cursor: "pointer",fontFamily: "inherit"}} onClick={hanldeFilterDateClick}>
                        <h4 className='white' style={{margin: 0}}>{selectedDay.label}</h4>
                            {filterDateOpen ? <ExpandLess style={{position: "relative",width: "3.6rem",height: "2rem",borderRadius: "3rem",marginTop: 0,color: "white"}}/> : <ExpandMore style={{width: "3.6rem",height: "2rem",borderRadius: "3rem",marginTop: 0,color: "white"}} />}
                    </ButtonBase>
                </div>
                {filterDateOpen &&
                 <Tabs
                  value={selectedDay.value}  
                  indicatorColor="primary"
                  variant="scrollable"
                  scrollButtons
                  allowScrollButtonsMobile
                  aria-label="scrollable force tabs example"
                  sx={{borderRadius: "0.9rem 0 0.9rem 0.9rem",backgroundColor: "rgb(10, 50, 77)",color: "white",".css-1aquho2-MuiTabs-indicator": {backgroundColor: "#87CEEB"},".css-sb0aay-MuiButtonBase-root-MuiTab-root.Mui-selected": {color: "#87CEEB"}}}
                  
                >
                    {days.map((day, index) => 
                    { 
                    return (
                      <Tab  
                         css = {css`font-family: monospace !important;`}
                         key={index} value={day.value} label={day.label} sx={{color: 'white',fontSize: "1.2rem"}} onClick={()=>handleDayChange(day)}/>
                    )})}
                </Tabs> }
            </div>
            {data?.length == 0 ? 
            <NoData msg={text['There is no bet slip']} height="60vh"/> :
            <div
                css={css`
                overflow-y: scroll;
                height: calc(${height}px - 130px); //115
                -ms-overflow-style: none;  /* Internet Explorer 10+ */
                scrollbar-width: none;  /* Firefox */
                ::-webkit-scrollbar {
                display: none !important;  /* Safari and Chrome */
                }
                `}
                ref={containerRef}
                onScroll={handleScroll}
                id= 'hello'
            
               >
                <InfiniteScroll
                css={css`
                -ms-overflow-style: none;  /* Internet Explorer 10+ */
                scrollbar-width: none;  /* Firefox */
                ::-webkit-scrollbar {
                display: none !important;  /* Safari and Chrome */
                }
                `}
                scrollableTarget='hello'
        
                dataLength={data?.length} //This is important field to render the next data
                next={fetchMoreData}
                hasMore={hasMore}
                loader={ <Loader btn/>}
                endMessage={
                  <p style={{ textAlign: 'center',color: 'white'}}>
                    <b style={{color: 'white' }}>End</b>
                  </p>
                }
                >
                 { data.map((d)=> {
                    return (
                        <div key={d.id}>
                            {d.betType == "single" && <BodyCard data={d}  slips={data}/>}
                            {d.betType == "mix_parlay" &&  <ParlayCard data={d} slips={data}/>}
                        </div>
                    )
                })}
                </InfiniteScroll>
            </div>
        }    
        </div>
    }
    </div>
  ) 
}

const getSlipStatus = (data,betType) =>{
//   let status ;
  const slipStatus = data?.slipStatus;
//   const betStatus = data?.betStatus;
//   if(betStatus  == 'rejected'){
//     status = 'refund';
//     return status;
//   }
//   for (let i = 0; i < data.bet.length; i++) { // not use anymore
//     const v = data.bet[i];
//     if(v.status == 'pending'){
//         status = "pending"
//         return status
//     }
//     if(v.status == 'loss'){
//         status = "loss"
//         return status
//     }
//     if(betType == 'body' && v.status == 'refund'){
//         status = "refund"
//         if(status != slipStatus) return slipStatus // to prevent this problem "Status changed to win but the amount is not completely calculated yet"
//         return status
//     }
//     if(betType == 'body' && v.status == 'draw'){
//         status = "draw"
//         if(status != slipStatus) return slipStatus // to prevent this problem "Status changed to win but the amount is not completely calculated yet"
//         return status
//     }
//   }
//   if(betType == "parlay"){ // not use anymore
//     if(data.bet.length > 1 && data.bet.every(v => v.status == 'refund')){ // if all matches are refunded in parlay, status must be refund
//       status = 'refund';
//       return status;
//     }
//   }
//   status = "win";

//   if(status != slipStatus) return slipStatus // to prevent this problem "Status changed to win but the amount is not completely calculated yet"
  return slipStatus
}
function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}
function getSlipStatusBg(slipStatus){
    let backgroundColor;
    if(slipStatus == 'pending') backgroundColor= "#A47E3B"
    if(slipStatus == 'win') backgroundColor= "#408E91"
    if(slipStatus == 'loss') backgroundColor= "#9C254D"
    if(slipStatus == 'refund') backgroundColor= "rgb(100 39 118)"
    if(slipStatus == 'draw') backgroundColor= "rgb(47 156 37)"
    return backgroundColor;
}

function getSlipStatusText(slipStatus,text){
   let word;
   if(slipStatus == "pending") word = text.Playing ;
   if(slipStatus == "win") word = text.Win ;
   if(slipStatus == "loss") word = text.Lose ;
   if(slipStatus == "refund") word = text.Refund ;
   if(slipStatus == "draw") word = text.Draw ;
   return word;
}

function BodyCard({data,slips}) {
    const navigate = useNavigate();
    const {lang} = useContext(languageContext);
    const text = getLangJson(lang);
    const handicap = !data.bet[0].odd ? "" : data?.bet[0]?.odd[data.bet[0]?.betData?.odd_type]?.home_team_has_hadicap ;
    const homeTeamLogo = data?.bet[0]?.match.homeTeam.logo;
    const awayTeamLogo = data?.bet[0]?.match.awayTeam.logo;

    const scores = data.bet[0].match.scores;
    const realTimeGoals = data.bet[0].match.goals;
    const periods = data.bet[0].betData.periods;
    const slipStatus = getSlipStatus(data,'body');
    let backgroundColor = getSlipStatusBg(slipStatus);

    let winnedAmount  = 0;
    if(slipStatus == 'refund') {
        winnedAmount = Number(data.betAmount).toLocaleString();
    }else{
        winnedAmount = data?.winnedAmount == null ? "-" : Number(data?.winnedAmount).toLocaleString()
    }
   
    let goals;
    if(periods == 'full_time') goals = realTimeGoals
    if(periods == 'first_time') goals = scores.halftime
    if(periods == 'second_time') goals = {away: scores.fulltime.away - scores.halftime.away,home: scores.fulltime.home - scores.halftime.home}
  return (
      <div  style={{padding: "0 2rem 2rem 2rem",}}>
        <Badge.Ribbon text={getSlipStatusText(slipStatus,text)} style={{color: backgroundColor,backgroundColor: backgroundColor,width: 'auto',height: "30px"}} >
         <div >
            <ButtonBase component="div" style={{backgroundColor: "#0A324D",borderRadius: "1.8rem",display: "block"}} onClick={()=> navigate('/bettedDetail',{state: {data,slips}})}>
            <div style={{display: "flex",padding: "1rem 2rem 0.1rem 2rem"}}>
                <div style={{display: "flex",justifyContent: "center",alignItems: "center"}}>
                    <h3 style={{margin: 0}} className="white">
                        {text.No} {data.shortId}
                    </h3>
                </div>
                {JSON.parse(data.completed) && <Avatar shape="square" size="default" style={{marginLeft: "1.2rem"}} >{text.Settled}</Avatar>}
                {/* <div style={{display: 'flex',borderRadius: "3rem",backgroundColor: backgroundColor,justifyContent: 'center',alignItems: "center",padding: "0.5rem 1.5rem"}}>
                    <h4 style={{margin: 0}} className="white">
                       {getSlipStatusText(slipStatus,text)}
                    </h4>
                </div> */}
            </div>
            <div style={{padding: "1.7rem 2rem"}}>
                <div style={{display: "flex",borderRadius: "1.3rem",borderWidth: "0 0 1px 0",borderStyle: "dashed",borderColor: "grey",paddingBottom: "1rem"}}>
                    <div style={{display: "flex",flexBasis: "30%",justifyContent: "start",alignItems: "center"}}>
                      <h5 style={{margin: 0,color: `${handicap ? "#BFDB38" : "white"}`}} >{lang != "en" ? data.bet[0].match.homeTeam.name_mm.split(/(\s+)/)[0] : data.bet[0].match.homeTeam.name_en.split(/(\s+)/)[0]}</h5>
                    </div>
                    <div style={{display: "flex",flexBasis: "15%",alignItems: "center"}}>
                      <Img src={[homeTeamLogo,config.defaultLogo]} alt="Logo" style={{width: "3rem",height: "3rem",borderRadius: "5rem"}}  loader={<Skeleton width={30} height={30} circle/>}/>
                    </div>
                    <div style={{display: "flex",flexBasis: "15%",justifyContent: "center",alignItems: "center"}}>
                      <h4 style={{margin: 0}} className="white"> {goals.home || 0} - {goals.away || 0}</h4>
                    </div>
                    <div style={{display: "flex",flexBasis: "15%",justifyContent: "end",alignItems: "center"}}>
                      <Img src={[awayTeamLogo,config.defaultLogo]} alt="Logo" style={{width: "3rem",height: "3rem",borderRadius: "5rem"}}  loader={<Skeleton width={30} height={30} circle/>}/>
                    </div>
                    <div style={{display: "flex",flexBasis: "30%",justifyContent: "end",alignItems: "center"}}>
                       <h5 style={{margin: 0,color: `${!handicap ? "#BFDB38" : "white"}`}}>{lang != "en" ? data.bet[0].match.awayTeam.name_mm.split(/(\s+)/)[0] : data.bet[0].match.awayTeam.name_en.split(/(\s+)/)[0]}</h5>
                    </div>
                </div>
                <div style={{borderWidth: "0 0 1px 0",borderStyle: "dashed",borderColor: "grey"}}>
                    <div style={{display: "flex"}}>
                        <div style={{display: "flex",justifyContent: "start",flexBasis: "33%"}}>
                            <h5 style={{fontWeight: "normal",margin: "0.5rem 0"}} className="white">{text.Type}</h5>
                        </div>
                        <div style={{display: "flex",justifyContent: "center",flexBasis: "33%"}}>
                            <h5 style={{fontWeight: "normal",margin: "0.5rem 0"}} className="white">{text['Your team']}</h5>
                        </div>
                        <div style={{display: "flex",justifyContent: "end",flexBasis: "33%"}}>
                            <h5 style={{fontWeight: "normal",margin: "0.5rem 0"}} className="white">{text.Odd}</h5>
                        </div>
                    </div>
                    <div style={{display: "flex"}}>
                        <div style={{display: "flex",justifyContent: "start",flexBasis: "33%"}}>
                            <h4 style={{margin: 0,marginBottom: "1.3rem"}} className="white">{text['Body/UN']}</h4>
                        </div>
                        <div style={{display: "flex",justifyContent: "center",flexBasis: "33%"}}>
                            <h4 style={{margin: 0,marginBottom: "1.3rem"}} className="white">{capitalizeFirstLetter(data.bet[0].betData.selected_team)}</h4>
                        </div>
                        <div style={{display: "flex",justifyContent: "end",flexBasis: "33%"}}>
                            <h4 style={{margin: 0,marginBottom: "1.3rem"}} className="white">{data.bet[0].betData.converted}</h4>
                        </div>
                    </div>
                    <div style={{display: "flex"}}>
                        <div style={{display: "flex",justifyContent: "start",flexBasis: "33%"}}>
                            <h5 style={{fontWeight: "normal",margin: "0.5rem 0"}} className="white">{text.Period}</h5>
                        </div>
                        <div style={{display: "flex",justifyContent: "center",flexBasis: "33%"}}>
                            <h5 style={{fontWeight: "normal",margin: "0.5rem 0"}} className="white">{text['Betted money']}</h5>
                        </div>
                        <div style={{display: "flex",justifyContent: "end",flexBasis: "33%"}}>
                            <h5 style={{fontWeight: "normal",margin: "0.5rem 0"}} className="white">{text['Won money']}</h5>
                        </div>
                    </div>
                    <div style={{display: "flex",paddingBottom: "1rem"}}>
                        <div style={{display: "flex",justifyContent: "start",flexBasis: "33%"}}>
                            <h4 style={{margin: 0}} className="white">
                                {data.bet[0].betData.periods == "full_time" && "FT"}
                                {data.bet[0].betData.periods == "first_time" && "1H"}
                                {data.bet[0].betData.periods == "second_time" && "2H"}
                            </h4>
                        </div>
                        <div style={{display: "flex",justifyContent: "center",flexBasis: "33%"}}>
                            <h4 style={{margin: 0}} className="white">{Number(data.betAmount).toLocaleString() }</h4>
                        </div>
                        <div style={{display: "flex",justifyContent: "end",flexBasis: "33%"}}>
                            <h4 style={{margin: 0}} className="white">{winnedAmount}</h4>
                        </div>
                    </div>
                </div>
                <div>
                    <div style={{display: "flex",justifyContent: "space-between",paddingTop: "1rem"}}>
                        <h5 style={{margin: 0,fontWeight: "normal"}} className="white">{text['Betted date']}</h5>
                        <h5 style={{margin: 0,fontWeight: "normal"}} className="white">{text['Match time']}</h5>
                    </div>
                    <div style={{display: "flex",justifyContent: "space-between",paddingTop: "1rem"}}>
                        <h4 style={{margin: 0,fontSize: "1.3rem",fontWeight: "normal"}} className="white">{moment(data.betDate).format('DD-MM-YYYY h:mmA')}</h4>
                        <h4 style={{margin: 0,fontSize: "1.3rem",fontWeight: "normal"}} className="white">{moment(data.bet[0].match.matchDate).format('DD-MM-YYYY h:mmA')}</h4>
                    </div>
                </div>
            </div>
            </ButtonBase>
         </div>
        </Badge.Ribbon>
     </div>
  )
}

function ParlayCard({data,slips}) {
    const navigate = useNavigate();
    const {lang} = useContext(languageContext);
    const text = getLangJson(lang);
    const slipStatus = getSlipStatus(data,'parlay');
    let winnedAmount  = 0;
    if(slipStatus == 'refund') {
        winnedAmount = Number(data.betAmount).toLocaleString();
    }else{
        winnedAmount = data?.winnedAmount == null ? "-" : Number(data?.winnedAmount).toLocaleString()
    }

    let backgroundColor = getSlipStatusBg(slipStatus);

  return (
    <div style={{padding: "0 2rem 2rem 2rem"}}>
        <Badge.Ribbon text={getSlipStatusText(slipStatus,text)} style={{color: backgroundColor,backgroundColor: backgroundColor,width: 'auto',height: "30px"}} >
        <div  >
            <ButtonBase component="div" style={{backgroundColor: "#0A324D",borderRadius: "1.8rem",display: "block"}} onClick={()=> navigate('/bettedDetail',{state: {data,slips}})}>
            <div style={{display: "flex",padding: "1rem 2rem 0.1rem 2rem"}}>
                <div style={{display: "flex",justifyContent: "center",alignItems: "center"}}>
                    <h3 style={{margin: 0}} className="white">
                        {text.No} {data.shortId}
                    </h3>
                </div>
                {JSON.parse(data?.completed) && <Avatar shape="square" size="default" style={{marginLeft: "1.2rem"}} >{text.Settled}</Avatar>}
                {/* <div style={{display: 'flex',borderRadius: "3rem",backgroundColor: backgroundColor,justifyContent: 'center',alignItems: "center",padding: "0.5rem 1.5rem"}}>
                    <h4 style={{margin: 0}} className="white">
                        {getSlipStatusText(slipStatus,text)}
                    </h4>
                </div> */}
            </div>
            <div style={{padding: "1.7rem 2rem"}}>
                <div style={{display: "flex",alignItems: "center"}}>
                    <div style={{flexBasis: "90%",borderWidth: "0 0 1px 0",borderStyle: "dashed",borderColor: "grey",marginRight: "1rem"}}>
        
                    </div>
                    <IconButton style={{border: "1px solid rgb(3, 119, 98)"}}>
                      <KeyboardDoubleArrowRightIcon style={{color: "white"}}/>
                    </IconButton>
                </div>
                <div style={{}}>
                    <div style={{display: "flex"}}>
                        <div style={{display: "flex",justifyContent: "start",flexBasis: "33%"}}>
                            <h5 style={{fontWeight: "normal",margin: "0.5rem 0"}} className="white">{text.Type}</h5>
                        </div>
                        <div style={{display: "flex",justifyContent: "center",flexBasis: "33%"}}>
                            <h5 style={{fontWeight: "normal",margin: "0.5rem 0"}} className="white">{text['Betted money']}</h5>
                        </div>
                        <div style={{display: "flex",justifyContent: "end",flexBasis: "33%"}}>
                            <h5 style={{fontWeight: "normal",margin: "0.5rem 0"}} className="white">{text['Won money']}</h5>
                        </div>
                    </div>
                    <div style={{display: "flex"}}>
                        <div style={{display: "flex",justifyContent: "start",flexBasis: "33%"}}>
                            <h4 style={{margin: 0,marginBottom: "1.3rem"}} className="white">{text['Parlay']}</h4>
                        </div>
                        <div style={{display: "flex",justifyContent: "center",flexBasis: "33%"}}>
                            <h4 style={{margin: 0,marginBottom: "1.3rem"}} className="white">{Number(data.betAmount).toLocaleString()}</h4>
                        </div>
                        <div style={{display: "flex",justifyContent: "end",flexBasis: "33%"}}>
                            <h4 style={{margin: 0,marginBottom: "1.3rem"}} className="white">{winnedAmount}</h4>
                        </div>
                    </div>
        
                </div>
                <div>
                    <div style={{display: "flex",justifyContent: "space-between",paddingTop: "1rem"}}>
                        <h5 style={{margin: 0,fontWeight: "normal"}} className="white">{text['Betted date']}</h5>
                        <h5 style={{margin: 0,fontWeight: "normal"}} className="white">{text['Total']}</h5>
                    </div>
                    <div style={{display: "flex",justifyContent: "space-between",paddingTop: "1rem"}}>
                        <h4 style={{margin: 0,fontSize: "1.3rem",fontWeight: "normal"}} className="white">{moment(data.betDate).format('DD-MM-YYYY h:mmA')}</h4>
                        <h4 style={{margin: 0,fontSize: "1.3rem",fontWeight: "normal"}} className="white">{data?.betCount} {text.Match}</h4>
                    </div>
                </div>
            </div>
            </ButtonBase>
        </div>
        </Badge.Ribbon>
    </div>
  )
}
