import React, { useEffect,useState } from 'react'
import { useContext } from 'react'
import GobackHeader from "../goBackHeader"
import { getLangJson, languageContext } from '../../context';
import { useLocation } from 'react-router-dom'
import moment from 'moment'
import axios from '../apiClient'
import Card from './matchCard'
import Loader from '../loader';

export default function BettedDetail() {
    const {lang} = useContext(languageContext)
    let text = getLangJson(lang);
    const location = useLocation();
    const [selectedCard,setSelectedCard] = useState([]);
    const [selectedMatches,setSelectedMatches] = useState([]);
    const [loading,setLoading] = useState(true)
    let {shortId,betType,betStatus,bet,betAmount,winnedAmount,betDate, id} = location.state.data;
    const data = location.state.data;

    async function fetchData() {
      setLoading(true)
      const res = await axios.get(`/history/getBetDetailBySlipId?slipId=${id}`);
      setLoading(false)
      if(res.code != 200) return;
      const selectedCardArr = [];
      const selectedMatchesArr = [];
      const betData = res.data?.bet;
      for (let i = 0; i < betData.length; i++) {
        const v = betData[i];
        const {converted,match_id : matchId , odd_type : oddType,periods, selected_team: selectedTeam} = v.betData
        selectedCardArr.push({converted, matchId,oddType,periods,selectedTeam})
        const {awayTeam,homeTeam,matchDate,scores,goals: realTimeGoals} = betData[i].match;
        let goals;
        if(periods == 'full_time') goals = realTimeGoals
        if(periods == 'first_time') goals = scores.halftime
        if(periods == 'second_time') goals = {away: scores.fulltime.away - scores.halftime.away,home: scores.fulltime.home - scores.halftime.home}
        const odd = [betData[i].odd];
        selectedMatchesArr.push({awayTeam,homeTeam,matchDate,matchId,odd,goals,status: betData[i].status,selectedPeriods: periods})
        setSelectedCard(selectedCardArr)
        setSelectedMatches(selectedMatchesArr)
      }
  }
 
    
    useEffect(()=>{
      fetchData();
    },[])

    const getSlipStatus = (data) =>{
      // let status ;
      const slipStatus = data?.slipStatus;
      // const betStatus = data?.betStatus;
      // if(betStatus  == 'rejected'){
      //   status = 'refund';
      //   return status;
      // }
      // for (let i = 0; i < data.bet.length; i++) {
      //   const v = data.bet[i];
      //   if(v.status == 'pending'){
      //       status = "pending"
      //       return status
      //   }
      //   if(v.status == 'loss'){
      //       status = "loss"
      //       return status
      //   }
      //   if(betType == 'single' && v.status == 'refund'){
      //       status = "refund"
      //       if(status != slipStatus) return slipStatus // to prevent this problem "Status changed to win but the amount is not completely calculated yet"
      //       return status
      //   }
      //   if(betType == 'single' && v.status == 'draw'){
      //       status = "draw"
      //       if(status != slipStatus) return slipStatus // to prevent this problem "Status changed to win but the amount is not completely calculated yet"
      //       return status
      //   }
      // }
      // if(betType == "mix_parlay"){
      //   if(data.bet.every(v => v.status == 'refund')){ // if all matches are refunded in parlay, status must be refund
      //     status = 'refund';
      //     return status;
      //   }
      // }
      // status = "win";

      // if(status != slipStatus) return slipStatus // to prevent this problem "Status changed to win but the amount is not completely calculated yet"
      
      // return status
      return slipStatus
    }
    
    const slipStatus = getSlipStatus(data);

    function getSlipStatusBg(){
      let backgroundColor;
      if(slipStatus == 'pending') backgroundColor= "#A47E3B"
      if(slipStatus == 'win') backgroundColor= "#408E91"
      if(slipStatus == 'loss') backgroundColor= "#9C254D"
      if(slipStatus == 'refund') backgroundColor= "rgb(100 39 118)"
      if(slipStatus == 'draw') backgroundColor= "rgb(47 156 37)"
      return backgroundColor;
    }

    let backgroundColor = getSlipStatusBg();

    function getSlipStatusText(){
      let word;
      if(slipStatus == "pending") word = text.Playing ;
      if(slipStatus == "win") word = text.Win ;
      if(slipStatus == "loss") word = text.Lose ;
      if(slipStatus == "refund") word = text.Refund ;
      if(slipStatus == "draw") word = text.Draw ;
      return word;
    }

    if(slipStatus == 'refund') {
        winnedAmount = Number(data.betAmount).toLocaleString();
    }else{
        winnedAmount = data?.winnedAmount == null ? "-" : Number(data?.winnedAmount).toLocaleString()
    }
    
  return (
    <div>
      <GobackHeader name={text['Bet Detail']}/>
      {loading && <Loader/>}
      {!loading && 
      <div style={{padding: "0 2rem"}} className="white">
        <div style={{padding: "1rem",margin: "1rem 0 3rem 0",display: "flex",justifyContent: "space-between",backgroundColor: "rgb(2, 25, 40)",borderRadius: "1rem"}}>
            <div style={{display: "flex",flexDirection: "column"}}>
                <div style={{display: "flex"}}>
                    <h5 style={{color: "#c2c2c2",fontWeight: "normal",margin: "0 0.4rem 1rem 0"}}>{text.No}</h5>
                    <h5 style={{color: "rgb(240 240 240)",fontWeight: "normal",margin: "0 0 1rem 0"}}>{shortId}</h5>
                </div>
                {betType == "mix_parlay" && <h5 style={{color: "#c2c2c2",fontWeight: "normal",margin: "0 0 0.8rem 0"}}>{text.Parlay}</h5>}
                {betType == "mix_parlay" && <h4 style={{color: "rgb(240 240 240)",margin: "0 0 1rem 0"}}>{bet.length}</h4>}
                {betType == "single" && <h5 style={{color: "#c2c2c2",fontWeight: "normal",margin: "0.8rem 0 0.8rem 0"}}>{text['Betted date']}</h5>}
                <h5 style={{fontWeight: "normal",margin: 0,color: "#c2c2c2"}}>{moment(betDate).format('DD-MM-YYYY h:mmA')}</h5>
            </div>
            <div style={{display: "flex",flexDirection: "column"}}>
                <h5 style={{color: "#c2c2c2",fontWeight: "normal",margin: "0 0 0.8rem 0"}}>{text['Betted money']}</h5>
                <h4 style={{color: "rgb(240 240 240)",margin: "0 0 1rem 0"}}>{Number(betAmount).toLocaleString()}</h4>
                <h5 style={{color: "#c2c2c2",fontWeight: "normal",margin: "0 0 0.8rem 0"}}>{text['Won money']}</h5>
                <h4 style={{margin: 0,color: "rgb(28 191 162)"}}>{winnedAmount}</h4>
            </div>
            <div style={{display: "flex",flexDirection: "column"}}>
                <div style={{padding: "0.6rem 0.8rem",backgroundColor: backgroundColor,borderRadius: "1rem",fontWeight: "bold"}}>
                  {getSlipStatusText()}
                </div>
            </div>
        </div>
        {
            selectedMatches.map((v)=>{
                let copyArr =selectedCard.length > 0 ? [...selectedCard] : []
                let obj = copyArr.filter(vv=>vv.matchId == v.matchId && vv.periods == v.selectedPeriods)[0]
                if(!obj) obj={};

                return(
                    <Card goals={v.goals} key={v.matchId} summary betTime={obj.periods}  matchData={v} handleBetMatchClick={()=>{}} selectedBetCard={[obj]} />
                )
            })
        }
      </div>
      }
    </div>
  )
}
