import React, { useContext, useEffect } from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { footerContext } from '../context';

const routes = ["/news","/newsDetails","/bettedHistory","/bettedDetail"]


export default function PrivateRoute() {
  const {currentPage,changePage} = useContext(footerContext)
  const {pathname} = useLocation(); 
  useEffect(()=>{
    changePage(pathname)
  },[pathname])
  if(!routes.includes(pathname)) localStorage.removeItem("cache") 

  const token = localStorage.getItem("token");
  return (
     token ? <Outlet/> : <Navigate to="/login"/>
  )
}
