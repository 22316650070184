import React,{ useState,useRef } from 'react'
 /** @jsxImportSource @emotion/react */
 import { useNavigate } from 'react-router-dom';
 import { Form, Input} from 'antd';
 import axios from './apiClient';
 import { LockOutlined,UserOutlined } from '@ant-design/icons';
 import {ButtonBase} from '@mui/material';
 import Alert from './alert';
import Loader from './loader';
import { useEffect } from 'react';
import { Navigate } from 'react-router-dom';

 
 
 export default function Login() {
   
   const [errorMsg, setErrorMsg] = useState({});
   const [openAlert, setOpenAlert] = useState(false);
   const [loading,setLoading] = useState(false)
   const navigate = useNavigate();
   const count = useRef(0);

  //  useEffect(()=>{
  //   if(localStorage.getItem("token")) navigate('/')
  //  },[])

   useEffect(() => {
    count.current = count.current + 1;
    },[]);

    const onFinish = async ({name,password}) => {
      const params ={
        "uname": name,
        "psw": password
      }
      setLoading(true)
      let data = await axios.post( '/member/loginMember',params)
      setErrorMsg({code: data.code,msg: data.message})
      setOpenAlert(true)
      setLoading(false)
      if(data.code != 200) return
      const token = data.data.token; 
      localStorage.setItem("token", token);
      window.location.reload()
    };
  
    const onFinishFailed = (errorInfo) => {
      // console.log("Failed:", errorInfo);
    };
    const handleCloseAlert =()=>{
      setOpenAlert(false)
    }
  return (
    <div style={{display: "flex",justifyContent: "center",alignItems: "center"}}>
        <div style={{display: "flex",flexDirection: "column",justifyContent: "center",alignItems: "center",marginTop: "30%"}}>
           <Alert openAlert={openAlert} errorMsg={errorMsg} handleCloseAlert={handleCloseAlert}/>
          { (count.current == 0 && localStorage.getItem("token")) ? <Navigate to="/"/> :
           <div style={{backgroundColor: "rgb(21 22 54)",padding: "1rem 1rem 3rem 0",borderRadius: "1rem"}}>
            <div style={{display: "flex",justifyContent: "center",alignItems: "center"}}><h3 style={{color: "white"}}>အကောင့်ထဲသို့ဝင်ရန်</h3></div>
            <div>
            <Form
              name="basic"
              labelCol={{
                span: 8,
              }}
              wrapperCol={{
                span: 16,
              }}
              style={{
                maxWidth: 600,
                marginTop: "2rem",
                fontFamily: "inherit"
              }}
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                label="အမည်"
                name="name"
                labelCol = {{span: 24}}
                wrapperCol = {{span: 24}}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: `အမည် လိုအပ်နေပါသည်`,
                  },
                ]}
                className="custom-input"
                style={{padding: "0 2rem",color: "white"}}
              >
                <Input  prefix={<UserOutlined className="site-form-item-icon" />} style={{backgroundColor: "rgb(10, 50, 77)"}} />
              </Form.Item>
              <Form.Item
                label="စကားဝှက်"
                labelCol = {{span: 24}}
                wrapperCol = {{span: 24}}
                name="password"
                className="custom-input"
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: `စကားဝှက် လိုအပ်နေပါသည်`,
                  }
                  // {
                  //   min: 6,
                  //   max: 15,
                  //   message: "စကားဝှက်မှာ ၆ မှ ၁၅ လုံးထိဖြစ်သင့်ပါသည်",
                  // },
                ]}
                style={{padding: "0 2rem",color: "white"}}
              >
                <Input.Password prefix={<LockOutlined className="site-form-item-icon" />} style={{backgroundColor: "rgb(10, 50, 77)"}} className="custom-input" />
              </Form.Item>
            
              <div style={{display: "flex",justifyContent: "center",alignItems:"center",padding: "0 2rem",marginTop: "5rem"}}>
                  <ButtonBase type='submit' component="button" style={{color: "white",display: "block",backgroundColor: "rgb(10, 50, 77)",padding: "1.6rem 0",borderRadius: "2rem",width: "100%",fontFamily: "inherit",justifyContent: "center",alignItems: "center"}}>
                    {loading ? <Loader btn/>: <h4 style={{textAlign: "center",margin: 0}}>ဝင်မည်</h4>}   
                  </ButtonBase>
              </div>

              {/* <div style={{display: "flex",justifyContent: "center",alignItems:"center",padding: "0 2rem",marginTop: "3rem",color: "white",fontFamily: "myanmar"}}>
                <ButtonBase onClick={()=> navigate("/register")}>
                  <h4 style={{textAlign: "center",margin: 0,color: 'gray'}}>အကောင့်ဖွင့်ရန်</h4>
                </ButtonBase>
              </div> */}
            </Form>
          </div>
           </div>}
        </div>
    </div>
  )
}
