import React from 'react';
import unicode from "./languages/unicode.json"
import zawgyi from "./languages/zawgyi.json"
import english from "./languages/en.json"

export const languageContext = React.createContext();

export const footerContext = React.createContext();

export const authContext = React.createContext();


export const getLangJson = (language) => {
    let text;
    if(language == "unicode"){
        text = unicode;
    }else if(language == 'zawgyi'){
        text = zawgyi;
    }else{
        text = english
    }
    return text
}

