 /** @jsxImportSource @emotion/react */
 import { css} from '@emotion/react';
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import { Input } from 'antd';
import { useState } from 'react';
import { useEffect } from 'react';
import {Img} from 'react-image'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import {ButtonBase} from '@mui/material';

import { getLangJson, languageContext } from '../../context';
import Loader from '../loader';
import Alert from '../alert';
import config from '../../config';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function FilterLeague({ leaguesArr,filterLeagues,hanldeFilterLeaguesClose, open ,handleFilterMatchesByLeagues }) {
    const {lang} = React.useContext(languageContext); 
    let text = getLangJson(lang);
    const [filterListItem, setFilterListItem] = useState([]);
    const [data, setData] = useState([]);
    const [leagues, setLeagues] = useState([]);
    const [loading,setLoading] = useState(true)
    const [errorMsg, setErrorMsg] = useState({});
    const [openAlert, setOpenAlert] = useState(false);
    const [selectedLeague,setSelectedLeague] = useState({})

    const handleListItemClick = (league) => {
        const leagueId = league.id;
        let copyArr;
        copyArr = [...filterListItem];
        // copyArr = [];
        // copyArr.push(leagueId)
        (filterListItem.includes(leagueId)) ? copyArr = copyArr.filter(v => v != leagueId) : copyArr.push(leagueId)
        setFilterListItem(copyArr)
        setSelectedLeague(league)
    }

    const handleSearch = (e) =>{
        let filteredData;
        if(lang != "en")  filteredData = data.filter(v => v.value_mm.toLowerCase().includes(e.currentTarget.value.toLowerCase()));
        if(lang == "en")  filteredData = data.filter(v => v.value_en.toLowerCase().includes(e.currentTarget.value.toLowerCase()));
        setLeagues(filteredData)
    }

    useEffect(()=>{
        setLeagues(leaguesArr)
        setData(leaguesArr)
        setFilterListItem(filterLeagues)
        setLoading(false)
    },[])

    const handleCloseAlert =()=>{
        setOpenAlert(false)
    }

    return (
        <div>
            <Alert openAlert={openAlert} errorMsg={errorMsg} handleCloseAlert={handleCloseAlert}/>
            <Dialog
                fullWidth
                maxWidth="xs"
                PaperProps={{
                    style: {
                        position: 'absolute',
                        width: "90vw",
                        top: 0,
                        bottom: 0,
                        backgroundColor: "#010625"
                    },
                }}
                open={open}
                onClose={hanldeFilterLeaguesClose}
                TransitionComponent={Transition}
            >
       
                <AppBar sx={{ position: 'relative'}} className="header-filter-league">
                    <Toolbar sx={{ justifyContent: "space-between", backgroundColor: "#021928" }}>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={hanldeFilterLeaguesClose}
                            aria-label="close"
                            sx={{ '&:hover': { backgroundColor: '#87CEEB' } }}
                        >
                            <CloseIcon fontSize='large' />
                        </IconButton>
                        <Input placeholder={text['Search...']} className='search-league' size='large' onChange={handleSearch}/>
                        <Button autoFocus color="inherit" onClick={()=>{handleFilterMatchesByLeagues(filterListItem,selectedLeague);hanldeFilterLeaguesClose();}}
                            sx={{ fontSize: "1.5rem", '&:hover': { backgroundColor: '#87CEEB' } }}>
                            {text.FILTER}
                        </Button>
                    </Toolbar>
                </AppBar>
                {loading && <Loader/>}
                {!loading &&
                <List   id='leagueFilter'
                css={css`
                        overflow-y: scroll;
                        -ms-overflow-style: none;  /* Internet Explorer 10+ */
                        scrollbar-width: none;  /* Firefox */
                        ::-webkit-scrollbar { 
                        display: none !important;  /* Safari and Chrome */
                    }
                    `}
                >
                    {leagues.map((v,index) => {
                        return (
                            <ButtonBase key={index} component="div" style={{display: "block"}}>
                                <ListItem  sx={{ color: "white" }} onClick={() => handleListItemClick(v)} key={v.id}>
                                    <ListItemAvatar >
                                        <Avatar  style={{borderRadius: "40px",width: '42px',height: '42px'}}>
                                        <Img
                                          width={42}
                                          height={42}
                                          style={{borderRadius: "45px",objectFit: "cover"}}
                                          src={[v.logo,config.defaultLogo]}
                                          loader={<Skeleton width={42} height={42} circle/>}
                                        />  
                                      
                                        </Avatar>
                                    </ListItemAvatar>
                                    <h3 style={{ color: 'white', fontSize: "1.5rem",fontFamily: 'myanmar',flex: 1 }}>{lang != "en" ? v.value_mm : v.value_en}</h3>
                                    {/* <ListItemText primary={lang != "en" ? v.value_mm : v.value_en} style={{fontFamily: "fantasy"}}
                                        primaryTypographyProps={{ style: { color: 'white', fontSize: "1.5rem",fontFamily: 'inherit' } }}
                                    /> */}
                                {filterListItem.includes(v.id)? <RadioButtonCheckedIcon sx={{ width: 24, height: 24, color: "#87CEEB" }} /> :  <RadioButtonUncheckedIcon sx={{ width: 24, height: 24 }} />}
                                </ListItem>
                            </ButtonBase>
                        )
                    })}

                </List>
                }
            </Dialog>
        </div>
    );
}