 /** @jsxImportSource @emotion/react */
 import { css} from '@emotion/react';
import React,{useState,useEffect, useRef} from 'react'
import jwt_decode from "jwt-decode";
import { useLocation, useNavigate } from "react-router-dom";
import { ButtonBase, Dialog, DialogContent, DialogTitle, ListItem, Slide } from '@mui/material';
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import parse from 'html-react-parser';

import parlayBackground from "../../assets/icons/parlay.png"
import bodyUNBackground from "../../assets/icons/body-UN.png"
import {getLangJson, languageContext} from "../../context"
import axios from "../apiClient";
import Alert from '../alert'
import Loader from '../loader';



export default function HomeBody({amount}) {
    const navigate = useNavigate();
    const [loading,setLoading] = useState(true)
    const [errorMsg, setErrorMsg] = useState({});
    const [openAlert, setOpenAlert] = useState(false);
    const [sysNoti, setSysNoti ] = useState('');
    const [openRuleBox, setOpenRuleBox ] = useState(false);
    const [rules, setRules ] = useState();
    const {lang} = React.useContext(languageContext); 
    const linkRef = useRef("");
    let text = getLangJson(lang);

     async function getSysNoti() {

        const res = await axios.get(`/info/getSystemNotification`)
        setLoading(false)
        if (res.code != 200)  return;
        const {desc_en,desc_uni,desc_zgi} = res.data;
        let msg ;
        if(lang == "unicode") msg = desc_uni;
        if(lang == "zawgyi") msg = desc_zgi;
        if(lang == "en") msg = desc_en;
        setSysNoti(msg)
    }

    const handleNavigationClick = (link) =>{
        linkRef.current = link;
        if(link == "/parlay" || link == "/bodyUN"){
            setOpenRuleBox(true)
        }else{
            GoToRoute();
        }
    }

    const GoToRoute = () => {
        navigate(`${linkRef.current}`,{state: {amount}});
    }

    const handleCloseAlert =()=>{
        setOpenAlert(false)
    }

    const ruleBoxSwitch =(val)=>{
        setOpenRuleBox(val)
    }

    const handleAgreeRule =()=>{
        GoToRoute();
    }

    const getRules = async () => {
        const res = await axios.get(`/info/getRules`)
      
        if (res.code != 200) {
          return
        }
        const desc = JSON.parse(res.data.description);
        setRules(desc);
      }

    useEffect(()=>{
        getSysNoti();
        getRules();
    },[])


  return (
    <div style={{padding: "2rem 1.5rem"}} >
        {openRuleBox && <RuleBox handleAgreeRule={handleAgreeRule} openRuleBox={openRuleBox} ruleBoxSwitch={ruleBoxSwitch} rules={rules}/>}
        <Alert openAlert={openAlert} errorMsg={errorMsg} handleCloseAlert={handleCloseAlert}/>
        {loading && <Loader/>}
        {!loading &&
        <div>
            <div  className='white running-text-container' style={{borderRadius: "1rem",background: "#2E5261",padding: "1rem",marginBottom: "3rem"}}>
                <h3 className='running-text ' style={{margin: 0,fontSize: "16px",display: "flex",alignItems: "center",fontFamily: "myanmar"}}><CampaignOutlinedIcon style={{fontSize: "3rem",marginRight: '1rem',color: "#ff9595"}}/> {sysNoti}</h3>
            </div>
            
            
            <ButtonBase component="div" css={css`background-image: url("../../assets/icons/parlay.png");`} style={{width: "100%",borderRadius: "2.2rem",marginBottom: "3rem",backgroundImage: `url(${parlayBackground})`,backgroundSize: "cover"}} onClick={()=>handleNavigationClick("/parlay")}>
                <h3 className='white' style={{margin: 0,textAlign: 'center',padding: "3.5rem 0",fontFamily: "myanmar"}}>{text.Parlay}</h3>
            </ButtonBase>
            <ButtonBase component="div" style={{width: "100%",borderRadius: "2.2rem",background: "#2E5261",marginBottom: "3rem",backgroundImage: `url(${bodyUNBackground})`,backgroundSize: "cover"}} onClick={()=>handleNavigationClick("/bodyUN")}>
                <h3 className='white' style={{margin: 0,textAlign: 'center',padding: "3.5rem 0"}}>{text['Body/UN']}</h3>
            </ButtonBase>
            <ButtonBase component="div"  style={{borderRadius: "2.2rem",background: "#0D1C44",marginBottom: "3rem",width: "100%"}} onClick={()=>handleNavigationClick("/accountStatement")}>
                <h3 className='white' style={{margin: 0,textAlign: 'center',padding: "1.7rem 0"}}>{text['Account Statement']}</h3>
            </ButtonBase>
            <ButtonBase component="div" style={{width: "100%",borderRadius: "2.2rem",background: "#0A324D",marginBottom: "3rem"}} onClick={()=>handleNavigationClick("/bettedHistory")}>
                <h3 className='white' style={{margin: 0,textAlign: 'center',padding: "1.7rem 0"}}>{text['Betted History']}</h3>
            </ButtonBase>
            <ButtonBase component="div" style={{width: "100%",borderRadius: "2.2rem",background: "#005853",marginBottom: "3rem"}} onClick={()=>handleNavigationClick("/checkBalance")}>
                <h3 className='white' style={{margin: 0,textAlign: 'center',padding: "1.7rem 0"}}>{text['Check Balance']}</h3>
            </ButtonBase>
        </div>
    }
    </div>
  )
}


const RuleBox = ({openRuleBox, ruleBoxSwitch, handleAgreeRule, rules}) =>{
    const {lang} = React.useContext(languageContext);
    let text = getLangJson(lang);
    
    const Transition = React.forwardRef(function Transition(props, ref) {
      return <Slide direction="up" ref={ref} {...props} />;
    });
  
    return (
      <Dialog
      open={openRuleBox}
      TransitionComponent={Transition}
      keepMounted
      aria-describedby="alert-dialog-slide-description"
      PaperProps={{style: {backgroundColor: "#010625",width: "70%",position: 'relative',zIndex : "30000",top: "5%",height: "80vh",width: "400px"}}}
    >
      <ButtonBase style={{ width: "50px", height: "50px", borderRadius: "25px",position: 'absolute',top: 0,right: 0,zIndex: 900 }} sx={{ '&:hover': { opacity: "0.8" } }} onClick={() => ruleBoxSwitch(false)}>
          <ClearRoundedIcon style={{ width: "3rem", height: "3rem", color: 'red' }} />
      </ButtonBase>
      <DialogTitle style={{color: "white",fontSize: "15px",textAlign: 'center'}}>{text['Rules']}</DialogTitle>
      <DialogContent>
        <div  className="ql-editor" style={{padding: "2rem 1rem"}}>
            {rules && parse(rules)}
         </div>
      </DialogContent>
        <ListItem button sx={{ color: "green",fontSize: "18px",justifyContent: 'center',backgroundColor: "whitesmoke" }} onClick={() => handleAgreeRule()}>
           {text.Agree}
         </ListItem>
  
    </Dialog>
    )
  }