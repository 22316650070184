import axios from 'axios';
import config from '../config';

const api = axios.create({
    baseURL: config.backendApi,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
});

api.interceptors.response.use(function (response) {
    // if(response.data.code != 200 && response.data.code != 404 && response.data.code != 500 && response.data.code != 409){
    if(response.data.code == 403 || response.data.code == 401){
        localStorage.removeItem("token");
        window.location.hash = "/login"
    }
    return response.data
  }, function (error) {
    // console.log(`Error from calling ${error.config.url}`)
    // console.log(error.message)
    return {
        code: "500",
        data: [],
        message: "Server Error"
    }
  });

export default api;