 /** @jsxImportSource @emotion/react */
 import { css} from '@emotion/react';
import React, { useContext, useEffect } from 'react'
import { getLangJson, languageContext } from '../../context'
import { useLocation } from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroll-component';
import { useState } from 'react';
import jwtDecode from 'jwt-decode';
import moment from 'moment';

import GoBackHeader from '../goBackHeader'
import Loader from '../loader';
import axios from '../apiClient';
import Alert from '../alert';
import NoData from '../noData';

export default function CheckBalance() {
  const {lang} = useContext(languageContext);
  const location = useLocation();
  const {amount} = location.state;
  const [trnxs,setTrnxs] = useState([]);
  const [hasMore,setHasMore] = useState(true);
  const [errorMsg, setErrorMsg] = useState({});
  const [openAlert, setOpenAlert] = useState(false);
  const [loading,setLoading] = useState(true)

   async function fetchData(fetchMore) {

    const token = localStorage.getItem("token");
    const {id: holderId} = jwtDecode(token);
    const from = trnxs.length;
    const to = trnxs.length + 10
    const data = await axios.get(`/wallet/getTransaction?holderId=${holderId}&s=${from}&c=${to}`)

    if (data.code != 200) {
        if(data.code == 404){
          setLoading(false)
            if(fetchMore){
              setHasMore(false)
            }else{
              setTrnxs([])
            }
            return;
        } 
        setErrorMsg({ code: data.code, msg: data.message })
        setOpenAlert(true)
        setLoading(false)
        return
    }
    const combineArr =[...trnxs,...data.data.transactions]
    setLoading(false)
    setTrnxs(combineArr)
   }

  useEffect(()=>{
    fetchData()
  },[])

  const fetchMoreData = ()=>{
    fetchData(true)
  }
  const handleCloseAlert =()=> setOpenAlert(false)

  const text = getLangJson(lang);
  return (
    <div>
        <GoBackHeader name={text.Balance}/>
        <Alert openAlert={openAlert} errorMsg={errorMsg} handleCloseAlert={handleCloseAlert}/>
        <div style={{display: 'flex',justifyContent: "space-between",padding: "0.9rem 2.7rem",backgroundColor: "#2E5261",marginBottom: "1.8rem"}}>
           <h3 style={{margin: 0}}>{text.Total}</h3>
           <h3 style={{margin: 0}}>{Number(amount).toLocaleString()} Units</h3>
        </div>
        {loading && <Loader/>}
        {!loading && 
        <div>
          {  trnxs.length == 0 ?
              <NoData msg={text['There is no lists']} height="60vh"/> :
              <InfiniteScroll
                  css={css`
                  -ms-overflow-style: none;  /* Internet Explorer 10+ */
                  scrollbar-width: none;  /* Firefox */
                  ::-webkit-scrollbar {
                  display: none !important;  /* Safari and Chrome */
                  }
                `}
                  dataLength={trnxs.length} //This is important field to render the next data
                  next={fetchMoreData}
                  hasMore={hasMore}
                  loader={ <Loader btn/>}
                  // scrollableTarget="betHistory"
                  endMessage={
                    <p style={{ textAlign: 'center',color: 'white' }}>
                      <b style={{color: 'white' }}>End</b>
                    </p>
                  }
                  >
                  { trnxs.map((t,index)=> {
                      return (
                        <Card key={index} trnx={t}/>
                      )
                  })}
              </InfiniteScroll>
          }
        </div>
        }   
    </div>
  )
}


function Card({trnx}) {
  const {trnxDate,adjustment,remark,reason} = trnx;
  return (
    <div style={{display: 'flex',justifyContent: "space-between",padding: "2rem 1.5rem",margin: "1.5rem 1.5rem",backgroundColor: "#0A324D",borderRadius: "1rem"}}>
      <div style={{display: 'flex',flexDirection: "column"}}>
        <h4 style={{margin: "0 0 0.5rem 0",fontFamily: "'Inter'"}} className="white">{reason}</h4>
        <h1 style={{margin: 0,fontSize: "1.2rem",fontWeight: "normal"}} className="white">{moment(trnxDate).format('MMM DD,YYYY h:mmA')}</h1>
      </div>
      <div style={{display: 'flex',justifyContent: "center",alignItems: "center"}}>
        <h3 className='white' style={{margin: 0}}>{Number(adjustment).toLocaleString()}</h3>
      </div>
   </div>
  )
}

